.detailed-report {

}

/* Main Title */
h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: left; /* Align title to the left */
}

/* Date Range Section */
.date-range {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.date-input {
    border: 1px solid #ddd;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 14px;
    cursor: default; /* Looks non-clickable */
}

/* "Most View" Heading */
.report-summary-heading {
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

/* Most Viewed and Last Watched Section */
.report-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.report-summary-item {
    flex: 1;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin: 0 10px;
    text-align: center;
}

.report-summary-item h3 {
    font-size: 14px;
    color: #aaa; /* Light grey color for labels */
    margin-bottom: 10px;
    text-align: left; /* Align heading to the left */
}

.report-summary-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin: 8px 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.report-summary-item p {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    text-align: center;
}

/* Total Duration Display */
.total-duration {
    font-size: 14px;
    color: #8e206b; /* Matches color for "Mins Watching" text */
    font-weight: bold;
    text-align: right;
}

/* List of Views Section */
.report-list {
    margin-top: 20px;
}

.report-list h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0px;
    text-align: left; 
}

.report-list p {
    font-size: 14px;
    color: #8e206b; 
    text-align: right;
}

/* Individual Report Item */
.report-item {
    display: flex;
    align-items: center;
    background: #f9f9f9;
    justify-content: space-between;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Light shadow for depth */
}

.report-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
}

.report-item h4 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.report-item p {
    font-size: 14px;
    color: #8e206b; 
    margin: 0;
    text-align: left;
}

.report-item .text-container {
    flex: 1; 
    margin-right: 15px;
}

/* Details Button Styling */
.details-button {
    background-color: #7a1b5c;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.details-button:hover {
}
