.react-dropzone-s3-uploader{
    position: relative;
    width: auto !important; 
    height: auto !important;
    border: 0px dashed rgb(153, 153, 153) !important; 
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}
.add-box
{
    background-image: url('../../images/upload-icon.png');
    background-repeat: no-repeat;
    background-position: center;
}
.rdsu-progress
{
    visibility: hidden !important;
}
.upload-icon-p
{
    padding-top: 100px;
}

.removeImage
{
    position: absolute;
    right: 10%;
    margin-top: 8px;
    cursor: pointer;
    background-color: #fff;
    padding: 0px 4px;
    font-size: 12px;
    border-radius: 2px;
    z-index: 9999;
}

.removeVideo
{
    position: absolute;
    right: 5%;
    margin-top: 10px;
    cursor: pointer;
    background-color: #fff;
    padding: 2px 7px;
    font-size: 14px;
    border-radius: 2px;
    z-index: 9999;
}
.img-edit span
{
    width: 100%;
}