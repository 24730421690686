.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid purple;
    border-bottom: 16px solid purple;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .audio .loader{
    border: 16px solid #f3f3f3;
    border-radius: 60%;
    border-top: 16px solid purple;
    border-bottom: 16px solid purple;
    width: 15px;
    height: 15px;
    margin-top: -15px;
    margin-left: -6px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  
/* 
 Audio Player 
 Remember to use !important to overwrite inline styles. */
.react-h5-audio-player  .toggle-play-wrapper .toggle-play-button {
      
  background-color: purple !important;
}
.react-h5-audio-player  .toggle-play-wrapper .pause-icon {
      
  box-shadow: purple 7px 0px 0px 0px inset !important;
}

.unitbox{
  padding: 0;
}
.unitbox .card-footer {
  padding: 7px;
}


.a-unitbox {
  align-items: center;
  justify-content: center;
}

.a-unitbox:hover {
  background: rgba(0,0,0,.03);
}

.a-unitbox img{
  width: auto;
  padding: 1rem;
}
.unitbox , .a-unitbox {
  border-radius: 0;
  height: 100%;
  margin-top: -11px;
}
