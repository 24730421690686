
@font-face {
    font-family: DroidSansArabi;
    src: url("../webfonts/DroidSansArabi.ttf");
    }

*{

    font-family: Arial, Helvetica, sans-serif;

}


html{
    height: 100%;
}

#root{
    background-image: linear-gradient(white, white);
    background-image: -webkit-linear-gradient(white, white);
    background-image: -moz-linear-gradient(white, white);
    background-color: white;
    min-height: 100vh;
    display: flow-root;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}



.h7{
    font-size: .8rem;
}

.small-font{
    font-size: .8rem;
}

.x-small-font{
    font-size: .7rem;
}

.main-color{
    color:#6f1a65 !important;
}

.gray-color{
    color: #9c9c9c;
}

.secondary{
    color:#4a5e80
}

.language-btn{
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-block;
    background: #831c77;
    border-radius: 50%;
    line-height: 30px;
    color: white;
}

.logout-btn {
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #831c77;
    border-radius: 50%;
    line-height: 0;
    color: white;
    padding: 8px;
}

.logout-btn img {
    width: 100%;
}

.language-btn:hover,.logout-btn:hover{
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    color: white;
    background-color: #79176d;
    text-decoration: none;
}

.main-logo{
    width: 73px;
}

.last-edit{
    color: #4a5e80;
    line-height: 30px;
}



/*\\\\\\ start login page\\\\\ */




.card-container.card {
    max-width: 350px;
    padding: 30px;
}

.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

/*
 * Card component
 */
.card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 30px;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    /* -moz-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06); */
}

.lock-icon {
    width: 75px;
    /* height: 79px; */
    margin: 0 auto 10px;
    display: block;
    /* -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%; */
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    color: #363636;
    margin: 10px 0 0;
    min-height: 2.5em;
}

.h5, h5 {
    font-size: 1.25rem;
}


.small-label{
    font-size: .75rem;
    color: #666
}

input[type="email"]::-webkit-input-placeholder,input[type="password"]::-webkit-input-placeholder {
    color: rgb(199, 199, 199);
  }

.form-signin #inputEmail,
.form-signin #inputPassword {
    font-size: 14px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: rgb(89, 17, 81);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(91, 18, 82, 0.4);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(91, 18, 82, 0.4);
}

.validation_message{
    font-size: .75rem;
    color: #e04c4c;
    position: relative;
    top: -6px;
}

.btn.btn-signin {
    /*background-color: #4d90fe; */
    background-color: #831c77;
    /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: #611358;
}

.btn.btn-signin:not(:disabled):not(.disabled).active,
.btn.btn-signin:not(:disabled):not(.disabled):active,
.show>.btn.btn-signin.dropdown-toggle{
    background-color: #611358;
    border-color: #611358;
}

.forgot-password {
    color: #611358;
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: #611358;
}

/*\\\\\\ end login page\\\\\ */

/*\\\\\\ start index page\\\\\ */

.container-body{
    background: white;
    border-radius: 10px;
}

/* start nav logo */

        .nav-logo{
            height: 70px;
            background: #490942;
            border-top-left-radius: 10px;
            padding: 15px;
            display: flex;
            margin-right: -15px;
        }

        .nav-logo .main-logo{
            width: 62px;
        }

        .nav-logo div{
            display: flex;
            align-items: center;
        }

        .nav-title{
            color: white;
            padding-left: 10px;
            letter-spacing: 1px;
        }

/* end nav logo */

/* start nav */

        .main-nav{
            background: #6f1a65;
            border-bottom-left-radius: 10px;
            /* padding: 15px; */
            display: flex;
            margin-right: -15px;
            height: calc(100% - 70px);
        }

        .main-nav ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        .main-nav ul li a {
            display: block;
            color: #e9cce6;
            padding: 8px 16px;
            text-decoration: none;
            border-bottom: 1px solid #64165b;
            -o-transition: all 0.218s;
            -moz-transition: all 0.218s;
            -webkit-transition: all 0.218s;
            transition: all 0.218s;
        }

        .main-nav ul li:last-child a {
            border-bottom: none;
        }

        .main-nav ul li a.active {
            background-color: #64165b;
            color: white;
            box-shadow: inset 4px 0 0 0  #f156a3;
        }

        .main-nav ul li a:hover:not(.active) {
            background-color: #64165b;
            box-shadow: inset 4px 0 0 0  #f156a3;
            color: white;
        }
/* end nav */

/* start status bar */

        .nav-items{
            height: 70px;
            background: #fcfcfc;
            border-top-right-radius: 10px;
            /* padding: 15px; */
            display: flex;
            margin-left: -15px;
            border-bottom: 1px solid #ecf0f5;
            justify-content: flex-end;
        }

        .search-div {
            display: flex;
            align-items: center;
        }

        .search-form .form-group {
            transition: all 0.35s, border-radius 0s;
            width: 170px;
            background-color: #fff;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
            border-radius: 5px;
            border: 1px solid #ccc;
            position: relative;
            margin-bottom: 0;
        }

        .search-form .form-group input.form-control {
            padding-right: 37px;
            border: 0 none;
            background: transparent;
            box-shadow: none;
            display:block;
        }

        .search-form .form-group i.form-control-feedback {
            position: absolute;
            top: 2px;
            right: 4px;
            z-index: 2;
            display: block;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: #9c9c9c;
            left: initial;
            font-size: 14px;
        }

        .main-icons{
            display: flex;
            align-items: center;
        }

        .main-icons a{
            margin: 0 7px;
        }

        .main-icons a:first-child{
            margin-left: 15px;
        }

        .main-icons a:last-child{
            margin-right: 15px;
        }

        .main-icons i{
            -o-transition: all 0.218s;
            -moz-transition: all 0.218s;
            -webkit-transition: all 0.218s;
            transition: all 0.218s;
        }

        .main-icons a:hover{
            text-decoration:none;
        }

        .main-icons a:hover i{
            color: #6f1a65;
        }

        .user-login{
            width: 150px;
            display: flex;
            align-items: center;
            border-left: 1px solid #efefef;
            padding-left: 14px;
            height: 70px;
            padding-right: 15px;
        }

        .user-login img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .admin-name{
            line-height: 14px;
            padding-left: 8px;
            display: flex;
        }

/* end status bar */



/* start main content */

        .main-content{
            padding:15px 15px 0 0;
            height: calc(100% - 70px);
        }

        .main-content .row{
            height: 100%;
        }

        /* start right activity */

            .display-item{
                display: none;
            }

            .right-activity{
                margin-top: -15px;
                padding-right: 0px;
                padding-left: 0px;
            }

            .bg-activity{
                background-color: #f8f8f8;
                height: 100%;
                border-bottom-right-radius: 10px;
                padding: 25px 15px;
            }

            .view-user-act{
                display: flex;
                margin-bottom: 10px;
            }

            .view-user-act img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
            .user-log{
                width: 100%;
                padding-left: 10px;
            }

            .user-log p{
                margin-bottom: 0;
            }

            .view-btn{
                color: #c5d1e6;
                background: #eaeaea;
                border-radius: 20px;
            }

            .view-btn:hover{
                color:#4a5e80;
            }

        /* end right activity */

/* end main content */







/*\\\\\\ end index page\\\\\ */


/*\\\\\\ start footer \\\\\ */

footer p{
    font-size: .8rem;
    color: white;
}

footer.footer-style p{
    color: #4a5e80;
}


/*\\\\\\ end footer \\\\\ */


/* \\\\\\ start user management page  \\\\\\ */

.alluserMng label {
    display: inline-block;
    margin-bottom: .1rem;
}

.control-filter-btn button{
    border: 1px solid #ddd;
    background: rgb(232, 232, 232);
    height: auto !important;
    font-size: 0.80rem;
    display: block;
    border-radius: 6px;
    margin: 3px;
    cursor: pointer;
}

.control-filter-btn button:hover{
    background-color: rgb(189, 189, 189);
    border: 1px solid rgb(189, 189, 189);

}

.control-filter-btn button.active{
    background-color: rgb(111, 26, 101);
    border: 1px solid rgb(111, 26, 101);
    color: white;
}

.control-filter-btn button i:first-child{
    font-size: 0.67rem;
}

/* \\\\\\ end user management page  \\\\\\ */


/* ---------start custom table ------ */

.responsive-table{
    overflow-x:auto;
}

.custm-table thead tr ,.table-header{
    background: #80497a;
    color: white;
}

.custm-table .custom-control-label {
    position: relative;
    margin-bottom: 15px;
    margin-left: -3px;
}



.responsive-table .navigation{
    display: flex !important;
    justify-content: center !important;
    align-items: baseline !important;
}

.t-user{
    display: inline-block;
    margin-left: 1rem;
}

.navigation .page-link {
    color: #7b7b7b;
}


.navigation .page-item.active .page-link {
    background-color: #adadad;
    border-color: #adadad;
}

.custm-table a.key , .custm-table a.user-lock , .custm-table a.trash , .custm-table a.ban{
    font-size: 1rem;
    margin: 0.3rem;
    color: #acacac;
}

.custm-table a.key:hover i , .custm-table a.user-lock:hover i , .custm-table a.trash:hover i , .custm-table a.ban:hover i{
    color: #6f1a65;
}

.custm-table a.key.active i , .custm-table a.user-lock.active i , .custm-table a.trash.active i , .custm-table a.ban:hover i{
    color: #6f1a65;
}

/* start checkbox */
.custm-table .custom-control{
    min-height: auto;
}

.custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before ,.custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before , .custm-table .custom-control-input:checked~.custom-control-label::before{
    background-color: #ad5fa4;
}

.custm-table .custom-control-input:focus~.custom-control-label::before{
    box-shadow: none;
}

.custm-table .custom-control-input {
    position: unset;
    z-index: auto;
    opacity: 0;
}
/* end checkbox */

/* ---------end custom table ------ */

/* ---------start custom-checkbox ------ */

.custom-control{
    min-height: auto;
}

.custom-control-label::before {
    top: 0.1rem;
}
.custom-control-label::after {
    top: .1rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before ,.custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before , .custm-table .custom-control-input:checked~.custom-control-label::before{
    background-color: #ad5fa4;
}

.custom-control-input:focus~.custom-control-label::before{
    box-shadow: none;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #ad5fa4;
}

.custom-control-input {
    /* position: unset; */
    z-index: auto;
    opacity: 0;
}

/* .custm-table .custom-control {
    padding-left: .75rem;
} */

.custom-control-label::before {
    background-color: #cacaca;
}

.l-notification .custom-control.custom-checkbox{
    display: inline-block;
}

.l-notification .small-label{
margin-bottom: .5rem;
}

.l-notification .custom-control-label::before {
    position: absolute;
    top: 0.14rem;
}

.l-notification .custom-control-label::after {
    top: .14rem;
}

/* ---------end custom-checkbox ------ */

/* ---------start custom-redio button ------ */


.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #6f1a65;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #6f1a65;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before{
    background-color: #6f1a65;
}

.custom-control-input:focus~.custom-control-label::before{
    box-shadow: none;
}

/* ---------end custom-redio button ------ */

/* \\\\\\ Start FAQ page  \\\\\\ */
#card-header{
    padding-bottom: 15px;
}

.acor-btn2{
    margin-left: 5px;
}

 .cstom-accordion .card-header {
    padding: .75rem 1rem;

}

.acor-btn-group{
    display: inline-block;

    /* float: right; */
}

.cstom-accordion .card-header button.btn.btn-link {
    padding: 0;
    color: inherit;
    text-decoration: none;
}

.cstom-accordion .card-header button.btn.btn-link i{
    margin-right: .33rem;
    /* margin-right: 10px; */
}


.acor-btn-group button{
    border: 1px solid #ddd;
    background: #f7f7f7;
    cursor: pointer;
    border-radius: .3rem;
    font-size: .9rem;
    padding: .4rem 1rem
}

.acor-btn-group button:hover{
    background: #ddd;
}


.cstom-accordion .card-header button.btn-link:not(.collapsed) i:before {
    content: "-";
    font-size: 30px;
    line-height: 10px;
}
.cstom-accordion .card-header button.btn-link i {
    color: #999;
    font-size: 12px;
}

/* \\\\\\ End FAQ page  \\\\\\ */


/* ----------------------------------- start Step 3  ------------------------------------- */

/* start evaluation-accordion */

.evaluation-accordion .checkout-step-title a {
    color: rgb(53, 53, 53);
}
.evaluation-accordion .checkout{
    background-color: #fff;
    /* border:1px solid #eaefe9; */
    font-size: 14px;
}
.evaluation-accordion .panel{margin-bottom: 0px;}
.evaluation-accordion .checkout-step {

    border-top: 1px solid #f2f2f2;
    color: #666;
    font-size: 14px;
    padding: 30px;
    padding-bottom: 0;
    position: relative;
}

.evaluation-accordion .checkout-step:nth-child(1) {
    border: 0;
}

.evaluation-accordion .checkout-step-number {
    border-radius: 50%;
    border: 5px solid #ebebeb;
    background-color: #242424;
    display: inline-block;
    font-size: 12px;
    height: 56px;
    margin-right: 6px;
    padding: 6px;
    text-align: center;
    width: 56px;
    line-height: 27px;
    color: #fff;
    font-size: 1.5rem;
    position: relative;
    z-index: 2;
}

.evaluation-accordion .step-number {
    position: relative;
    padding-bottom: 30px;
}


.evaluation-accordion .step-number:after {
    content: " ";
    position: absolute;
    left: 28px;
    z-index: 1;
    width: 1px;
    height: 87px;
    overflow: hidden;
    background-color: #f2f2f2;
}

.evaluation-accordion .panel:not(:first-child) .step-number:after {
    content: " ";
    position: absolute;
    top: -30px;
    left: 28px;
    z-index: 1;
    width: 1px;
    height: 120px;
    overflow: hidden;
    background-color: #f2f2f2;
}

.evaluation-accordion .checkout-step-title{ font-size: 18px;
    font-weight: 500;
    vertical-align: middle;display: inline-block; margin: 0px;
}

.evaluation-accordion .step-content{
    position: relative;
    width: calc(100% - 28px);
    max-width: 85%;
    margin-left: 28px;
    padding: 0 37px;
    padding-bottom: 30px;
    /* height: calc(100% + 30px); */
    border-left: 1px solid #f2f2f2;
    /* background: red; */
}

/* end evaluation-accordion */



/* -----start modal content---- */
.custom-serch{
    position: relative;
}

.custom-serch i{
    position: absolute;
    left: 14px;
    top: 13px;
    color: #495057;
}

.custom-serch .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 35px;
}


.modal-body .navigation{
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.article-box{
    border: 1px solid #e9ecef;
    padding: 1rem;
}

.c-checkbox{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-checkbox .custom-control-label::after {
    top: -0.9rem;
    left: -.9rem;
}

.c-checkbox .custom-control-label::before {
    top: -0.9rem;
    left: -.9rem;
}


img.modal-img{
    vertical-align: middle;
    border-style: none;
    width: 100%;
}

.post-icons{
    border-top: 1px solid #e9ecef;
}

.post-icons a.viewNum{
    color:rgb(53, 53, 53);
}

.post-icons a.like{
    color:#b11b9f;
}


.post-icons a.comment{
    color:#d01721;
}



.post-icons a.share{
    color:#1788d0;
}


.post-icons a:hover{
    text-decoration: none;
}


/* -----end modal content---- */
/* in View Customer data*/
/* start children-box */

.children-box{
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    padding: 1rem;
}

.first-row{
    position: relative;
    border-bottom: 1px solid#ddd;
    /* padding-left: 77px */

}

.first-row > div , .second-row > div {
    width: calc(100% - 120px);
    float: right;
}

.first-row > div{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
}

.first-row , .second-row{
    min-height: 60px;
}

.second-row{
    padding-top: 1rem;
}

.Chaild-pic{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    bottom: -50px;
}


.chaild-title{
    display: inline-block;
}


.first-row input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.first-row label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 30px;
    background: #eaeaea;
    border: 1px solid #d4d4d4;
	display: inline-block;
	border-radius: 100px;
	position: relative;
}

.first-row label:after {
	content: '';
	position: absolute;
    top: 3px;
    left: 4px;
    width: 22px;
    height: 22px;
	background: #581260;
	border-radius: 90px;
	transition: 0.3s;
}


.first-row label{
    margin: 0;
}

.first-row input:checked + label {
    background: #fff;
    border: 1px solid #d4d4d4;
}

.first-row input:checked + label:after {
	left: calc(100% - 4px);
	transform: translateX(-100%);
}

.first-row label:active:after {
	width: 23px;
}

.chaild-btns button{
    width: calc(50% - 2px);
    color: inherit;
    border: 1px solid #ddd;
}
.chaild-btns .btn-outline-secondary:hover{
    border: 1px solid #ddd !important;
    background: #f7f7f7 !important;
    color: inherit !important;
}

.chaild-btns .btn-outline-secondary:not(:disabled):not(.disabled):active{
    border: 1px solid #ddd !important;
    background: #f7f7f7 !important;
    color: inherit !important;
}

.chaild-btns .btn-outline-secondary:focus , .chaild-btns .btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

/* end children-box */


/*\\\\\\\\\\\\\\\\\\\************************************************************\\\\\\\\\\\*/
/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*********Added By Sara************\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
/*\\\\\\\\\\\\\\\\\\\************************************************************\\\\\\\\\\\*/

/* \\\\\\ Start Customer page  \\\\\\ */

.customer_icons{
    background-color: #c2c2c2;
    color: white;
    border: 1.5px solid #acacac;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 6px;
    float: right;
    margin-right:5px;
    margin-top: -20px ;
    box-shadow: none;
}
.customer_icons:hover{
    background-color: white;
    color: Purple;
    border: 1.5px solid purple;
}

.customer_buttons{
    background-color: white;
    color: purple;
    border: 1.5px solid Purple;
    border-radius: 7px;
    font-size: 14px;
    padding: 5px 10px;
    float: right;
    margin-top: -20px
}

/* \\\\\\ End Customer page  \\\\\\ */

/*\\\\\\\\\\\\\\\\\\\ start view customer page\\\\\\\\\\\*/
#Reset_password {
    background-color: rgb(36, 95, 184);
    color: white;
}

#View_customer_save {
    background-color: green;
    color: white;
    float: right;
}

.image_Preview_circle {
    text-align: center;
    line-height: 96px;
    height: 120px;
    width: auto;
    margin: 10px 20px;
    border: 2px solid red;
    border-radius :50% ;
    transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
    object-fit: scale-down;
}

/*
 * Card component
 */
 .customer-card-container.card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
     margin-top: 30px;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
}

.children_container{
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.customer-hr{
    background-color: blue;
}

#child-data{
    display: inline-block;
}

.customer-button{
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 15%;
}
#customer-button-view{
    background-color: white;
    color: #64165b;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 15%;
}
#customer-button-edit{
    background-color: white;
    color: rgb(36, 95, 184);
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 15%;
}
.child-toogle{
    margin-top: 20px;

}


/* .customer-buttons{ */
    /* margin-top: 50px;     */

/* } */
/* \\\\\\\\\\\\\\\\\\\\\\\\  End view customer page \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
/* \\\\\\\\\\\\\\\\\\\\\\\\  start vendor report page \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.vendorbox{
    margin-top: 70px;
    border-width: 1px;
    border-color: rgb(239, 239, 239);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 8px 36.8px 3.2px rgba(111, 26, 101, 0.13);
    height: 113px;
}
.circle-tile {
    margin-bottom: 15px;

    width: 50px;
    height: 50px;
    position: absolute;
    top: -50px;
    left: 20;
}
.ayhaga{
    margin-top:25px;
    font-size: 12px;
    font-weight: 600;
}
.numbers{
    line-height: 55px;
    font-size: 20px;
    font-weight: bold;
    }

 .vendorEditayhaga{
    margin-top:40px;
}
.vendorEditnumbers{
    line-height: 55px;
    font-size: 30px;
    font-weight: bold;

    }
/* \\\\\\\\\\\\\\\\\\\\\\\\  End vendor report page \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
/* \\\\\\\\\\\\\\\\\\\\\\\\ Promo Codes CSS \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
.codeboxs{
    border-width: 1px;
    border-color: rgb(239, 239, 239);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 3.8px 0.2px rgb(211,211,211);
    height: 113px;
}


.filepond--media-preview {
    height: 360px !important;
}

.filepond--file{
    height: 360px !important;
}
.filepond--item{
    height: 360px !important;
}
.filepond--file-wrapper{
    height: 360px !important;
}

.filepond--media-preview-wrapper {
    height: 360px !important;
}

.filepond--media-preview video{
    height: 100%;
}

.loader-line {
    width: 100%;
    height: 5px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    margin: 10px 0px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 5px;
    width: 40%;
    background-color: #6f1a65;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}

.videoContainer {
    position: relative;
    width: 100%;

  }
  
  .deleteButton {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    cursor: pointer;
    background-color: #fff;
    padding: 2px 7px;
    font-size: 14px;
    border-radius: 2px;
    z-index: 9999;
    border: none;
  }

  
  