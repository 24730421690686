.views-details {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
}

.header-det-view {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    margin-bottom: 20px;
    background: #f5f5f5;
    padding: 15px;
    border-radius: 12px;
    width: 100%; 
    max-width: 100%; 
    box-sizing: border-box; 
}

.view-title {
    text-align: left;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
}


.series-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px; 
}

.series-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 15px;
}

.series-info h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.series-info p {
    font-size: 18px;
    color: #000000;
    margin: 5px 0 0 0;
}

.episodes-section {
    margin-bottom: 20px;
}

.episodes-section h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.summary {
    display: flex;
    justify-content: space-between;
}

.summary-item {
    width: 48%;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.summary-item h4{
    text-align: left;
    color: #999999;
}

.episode-thumbnail img {
    width: 250px;
    height: 200px;
    border-radius: 12px;
    margin-bottom: 10px;
}

.episode-thumbnail p {
    margin: 0;
    font-weight: bold;
}

.episodes-list {
    margin-top: 20px;
}

.episodes-list h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.total-duration {
    font-size: 16px;
    color: #6a1c66;
    text-align: right;
    margin-bottom: 10px;
}

.episodes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.episode-item {
    width: calc(50% - 7.5px);
    background: #f9f9f9;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.episode-item img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-right: 10px;
}

.episode-details h4 {
    margin: 0;
    font-size: 16px;
}

.episode-details p {
    margin: 0;
    color: #555;
    font-size: 14px;
}

.views-count {
    margin-left: auto;
    padding: 5px 10px;
    background: #fffaff;
    border-radius: 12px;
    font-size: 14px;
    color: #6a1c66;
    font-weight: bold;
}

.highlight-text {
    color: #000000;
    font-weight: bold;
}
