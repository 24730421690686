
.SongsButtons 
{
    background-color: white;
  color: purple;
  border: 1.5px solid Purple;
  border-radius: 7px;
  font-size: 14px;
  padding: 5px 10px;
  float: right;
  margin-right:14px;
  margin-top: -20px
}
.circles-labels{
  margin-top: -10px
}

.labelFloat {
  float: right;

}

.songCardButtons
{
    background-color: white;
    color: black;
    border: 1px solid gray;
    border-radius: 7px;
    font-size: 14px;
    padding: 5px 10px;
    float: right;
    margin-right:14px;
}

#labelName{
  font: bold;
  text-align: center

}

#editButton{
  float: right;
}

.link{
  text-decoration: none;
  text-decoration-color: black;
  text-decoration-style: none;
  color:black;
}

.link:hover{

  text-decoration: none;
  text-decoration-color:purple;
  text-decoration-style: none;
  color:purple;
  
}

.label{
  color:gray
}

.activeCircle{
  color: white;
  background-color: #7d276d;
  box-shadow: 0px 0px 6px 0px rgba(2, 2, 2, 0.4);

}

.nonActive{
  margin-left: 40px;
  color:gray
}



.circle{
  display: inline-block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: small;
  cursor: pointer;
  border: 2px solid #fff;
  line-height: 49px;
  text-align: center;
  border-color: rgb(213, 213, 213);
}

.circle2{
  margin-left: 44px
}

.circle-label{

  display: inline;
  text-align: center;
  color: gray;
  margin-left: 24px

}

.active-label
{
  color: purple
}
#nextButton{
  float: right;
  background: purple;
  color: white;
}

.drop-video{
  border: 1px dashed #e7e7e7;
  text-align: center;
  background: #fbfbfb;
  padding: 0.5rem;
  position: relative;
  line-height: 250px;
  height: 250px;
}

.drop-image
{
  border: 1.5px dashed;
  text-align: center;
  background-color: #F5F5F5;
  border-color: gray;
  line-height: 96px;
  height: 150px;
  color: gray;
}
.pDrop-video
{
  margin-top: -106px;

}
.pDrop-image
{
  margin-top: -28px;

}

#song-radio{
  margin-left: 40px
}
