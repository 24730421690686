/*--------------------------------------------------------------------------------- for large screen ---------------------------------------------------------------------------------*/

@media (min-width: 1200px) {
}

/******************************************---end large screen---*******************************************/

/*--------------------------------------------------------------------------------- for medium screen ---------------------------------------------------------------------------------*/

@media (min-width: 992px) and (max-width: 1199px) {
    .main-payment .bg-img {
        background-size: inherit !important;
    }

}

/******************************************---end medium screen---******************************************/

/*--------------------------------------------------------------------------------- for small screen ---------------------------------------------------------------------------------*/

@media (min-width: 768px) and (max-width: 991px) {

}

/******************************************---end small screen---*******************************************/

/*--------------------------------------------------------------------------------- for mobile & tablet screen ---------------------------------------------------------------------------------*/

@media (max-width: 767px) {
    .main-payment .pay-box .card-container.card{
        width: 100%;
    }

    .main-payment .Plans-box .col-12:first-child .card-header {
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    }
    
    .main-payment .Plans-box .col-12:first-child .card {
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    }
    
    .main-payment .Plans-box .col-12:last-child .card-header {
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    }
    
    .main-payment .Plans-box .col-12:last-child .card {
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    }

    .main-payment .history-card .card-body p {
        padding: 0;
        padding-bottom: .3rem;
    }
    .main-payment .history-card .card-body .custom-margin{
        margin-top:.8rem; 
    }
}

/**************************************---end mobile & tablet screen---*************************************/




