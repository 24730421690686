


.question{
  background-color:#F5F5F5;
  border: 0.5px solid;
  border-color: #DCDCDC	;
  min-height: 100px;
  margin-left: 12px

}
.questionOuterDiv
{
    color:#202020;


}

.addedQuestions{
  border: 0.5px solid;

  border-color: #DCDCDC	;
  margin-left: 20px


}

#addAnswerButton{

float: right;
background-color:  #3333ff;
padding-left: 30px;
padding-right: 30px;
text-align: center

}