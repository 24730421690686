.buttons_container{
    /* border: 1px solid red; */
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

#episode_publish{
    float: right;
    background: purple;
    color: white;
    width: 100px;
    margin-left:  10px;
}

#episode_backButton {
    float: right;
    background: plum;
    color: white;    
}

#episode_save{
    float: right;
    background: green;
    color: white;
    margin-left:  10px;
    width: 100px;
}

#episode_cancel{
    background: black;
    color: white;
    width: 100px;
}

.episode_cancel{
    background: black;
    color: white;
    width: 100px;
}
#nextButton{
    float: right;
    background: purple;
    color: white;
    margin-left:  10px;
  }

#btn-delete{
    color: white;
    background-color: rgb(7, 7, 107);
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}  