.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.custom-modal {
    background-color: #ffffff;
    width: 400px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.custom-modal-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.close-button {
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #000000;
}

.custom-modal-body {
    margin: 20px 0;
}

.custom-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.save-button {
    background-color: #701c64;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
}

/* Group Status Toggle */
.group-status-toggle {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.group-status-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.group-status-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.group-status-slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.group-status-toggle input:checked + .group-status-slider {
    background-color: #8054dc;
}

.group-status-toggle input:checked + .group-status-slider:before {
    transform: translateX(14px);
}
