
@font-face {
    font-family: DroidSansArabi;
    src: url("../webfonts/DroidSansArabi.ttf");
    }

@font-face {
    font-family: Raleway;
    src: url("../webfonts/Raleway/Raleway-Regular.ttf");
    }




*{
    font-family: "Raleway",Arial, Helvetica, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


/* html{
    min-height: 100%;
}

body{
    background-image: linear-gradient(#59cef1, #55dedb);
    background-image: -webkit-linear-gradient(#59cef1, #55dedb);
    background-image: -moz-linear-gradient(#59cef1, #55dedb);
    font-size: 0.9rem;
} */
/* 
.left-main-body{
    background: white;
    padding-bottom: 1rem;
} */

/* .left-main-body .card-body{
    background: white;
} */

.h7{
    font-size: .8rem;
}

.small-font{
    font-size: .8rem ;
}

.x-small-font{
    font-size: .7rem;
}

.main-color{
    color:#6f1a65 !important;
}

.gray-color{
    color: #9c9c9c;
}

.secondary{
    color:#4a5e80
}

.language-btn{
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-block;
    background: #831c77;
    border-radius: 50%;
    line-height: 30px;
    color: white;
}

.logout-btn {
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #831c77;
    border-radius: 50%;
    line-height: 0;
    color: white;
    padding: 8px;
}

.logout-btn img {
    width: 100%;
}

.language-btn:hover,.logout-btn:hover{
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    color: white;
    background-color: #79176d;
    text-decoration: none;
}

.main-logo{
    width: 73px;
}

.last-edit{
    color: #4a5e80;
    line-height: 30px;
}




.section-title{
    position: relative;
    display: flex;
}

.section-title span{
    position: absolute;
    left: 0;
    background: white;

}

.section-title:before{
    position: absolute;
    content: " ";
    display: flex;
    justify-content: center;
    border: 1px solid #ddd;
    width: 100%;
}


  div.hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
    text-align: left;
    line-height: 0px !important;
  }
  
 .hr-title{
    line-height: 0px !important;
    background: white;
    padding-right: 1rem;
 }
  






.btn.btn-nxt , .btn.btn-bck, .btn.btn-cls{
    padding:0 1.5rem;
}



.btn.btn-nxt , .btn.btn-bck, .btn.btn-cls , .btn-suc , .btn-pry{ 
    font-weight: 100;
    font-size: 14px;
    height: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    margin: 5px 0;
}

.btn.btn-nxt:hover, .btn.btn-bck:hover, 
.btn.btn-nxt:active, .btn.btn-bck:active, 
.btn.btn-nxt:focus, .btn.btn-bck:focus {
    background-color: #611358;
}

.btn.btn-nxt:not(:disabled):not(.disabled).active, .btn.btn-bck:not(:disabled):not(.disabled).active,
.btn.btn-nxt:not(:disabled):not(.disabled):active, .btn.btn-bck:not(:disabled):not(.disabled):active,
.show>.btn.btn-nxt.dropdown-toggle , .show>.btn.btn-bck.dropdown-toggle{
    background-color: #611358;
    border-color: #611358;
}

.btn.btn-cls:hover,
.btn.btn-cls:active,
.btn.btn-cls:focus {
    background-color: #353535;
}

.btn.btn-cls:not(:disabled):not(.disabled).active,
.btn.btn-cls:not(:disabled):not(.disabled):active,
.show>.btn.btn-cls.dropdown-toggle{
    background-color: #353535;
    border-color: #353535;
}


.btn.btn-nxt {
    /*background-color: #4d90fe; */
    background-color: #831c77;
}


.btn.btn-bck {
    /*background-color: #4d90fe; */
    background-color: #ac50a1;
}


.btn.btn-cls {
    /*background-color: #4d90fe; */
    background-color: #252525;
}




.btn-add{
    padding: .7rem;
    border: 1px solid #ddd;
    background: rgb(232, 232, 232);
    height: auto !important;
    font-size: 0.88rem;
    color: #212529;
}

.btn-add:hover,
.btn-add:active,
.btn-add:focus {
    background-color: rgb(189, 189, 189);
    border-color: rgb(189, 189, 189);
    color: #212529;
}

.btn-add:not(:disabled):not(.disabled).active,
.btn-add:not(:disabled):not(.disabled):active,
.show>.btn.btn-add{
    background-color: rgb(189, 189, 189);
    border-color: rgb(189, 189, 189);
    color: #212529;
}

.add-ansrs-btn{
    background-color: #2762bc;
}





.btn-pry{
    padding: .7rem;
    border: 1px solid #16ade5;
    background: #16ade5;
    height: auto !important;
    font-size: 0.88rem;
}

.btn-pry:hover,
.btn-pry:active,
.btn-pry:focus {
    background-color: #129ed1;
    border-color: #129ed1;

}

.btn-pry:not(:disabled):not(.disabled).active,
.btn-pry:not(:disabled):not(.disabled):active,
.show>.btn.btn-pry{
    background-color: #129ed1;
    border-color: #129ed1;
}








button.btn.btn-outline-secondary{
    height: 38px;
    border-color: #ced4da;
}



button.btn.btn-outline-secondary i{
    color:#6c757d;
}

button.btn.btn-outline-secondary:hover{
    background: rgb(220, 109, 109);
    color:white;
    border-color: rgb(220, 109, 109);
}

button.btn.btn-outline-secondary:hover i{
    color: white;
}
















/*\\\\\\ start login page\\\\\ */




.card-container.card {
    max-width: 350px;
    padding: 30px;
}

/* .btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
} */

/*
 * Card component
 */
 .card-container.card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 30px;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
}

.lock-icon {
    width: 75px;
    /* height: 79px; */
    margin: 0 auto 10px;
    display: block;
    /* -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%; */
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    color: #363636;
    margin: 10px 0 0;
    min-height: 2.5em;
}

.small-label{
    font-size: .75rem;
    color: #666
}

input[type="email"]::-webkit-input-placeholder,input[type="password"]::-webkit-input-placeholder {
    color: rgb(199, 199, 199);
  }

.form-signin #inputEmail,
.form-signin #inputPassword {
    font-size: 14px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: rgb(89, 17, 81);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(91, 18, 82, 0.4);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(91, 18, 82, 0.4);
}

.validation_message{
    font-size: .75rem;
    color: #e04c4c;
    position: relative;
    top: -6px;
    margin-top: 10px !important;
}

.btn.btn-signin {
    /*background-color: #4d90fe; */
    background-color: #831c77;
    /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: #611358;
}

.btn.btn-signin:not(:disabled):not(.disabled).active,
.btn.btn-signin:not(:disabled):not(.disabled):active,
.show>.btn.btn-signin.dropdown-toggle{
    background-color: #611358;
    border-color: #611358;
}

.forgot-password {
    color: #611358;
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: #611358;
}

/*\\\\\\ end login page\\\\\ */

/*\\\\\\ start index page\\\\\ */

.container-body{
    background: white;
    border-radius: 10px;
}

/* start nav logo */

        .nav-logo{
            height: 70px;
            background: #490942;
            border-top-left-radius: 10px;
            padding: 15px;
            display: flex;
            margin-right: -15px;
        }

        .nav-logo .main-logo{
            width: 62px;
        }

        .nav-logo div{
            display: flex;
            align-items: center;
        }

        .nav-title{
            color: white;
            padding-left: 10px;
            letter-spacing: 1px;
        }

/* end nav logo */

/* start nav */

        .main-nav{
            background: #6f1a65;
            border-bottom-left-radius: 10px;
            /* padding: 15px; */
            display: flex;
            margin-right: -15px;
            height: calc(100% - 70px);
        }

        .main-nav ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }
        
        .main-nav ul li a {
            display: block;
            color: #e9cce6;
            padding: 8px 16px;
            text-decoration: none;
            border-bottom: 1px solid #64165b;
            -o-transition: all 0.218s;
            -moz-transition: all 0.218s;
            -webkit-transition: all 0.218s;
            transition: all 0.218s;
        }

        .main-nav ul li:last-child a {
            border-bottom: none;
        }
        
        .main-nav ul li a.active {
            background-color: #64165b;
            color: white;
            box-shadow: inset 4px 0 0 0  #f156a3;
        }
        
        .main-nav ul li a:hover:not(.active) {
            background-color: #64165b;
            box-shadow: inset 4px 0 0 0  #f156a3;
            color: white;
        }
/* end nav */

/* start status bar */

        .nav-items{
            height: 70px;
            background: #fcfcfc;
            border-top-right-radius: 10px;
            /* padding: 15px; */
            display: flex;
            margin-left: -15px;
            border-bottom: 1px solid #ecf0f5; 
            justify-content: flex-end;
        }

        .search-div {
            display: flex;
            align-items: center;
        }

        .search-form .form-group {
            transition: all 0.35s, border-radius 0s;
            width: 170px;
            background-color: #fff;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
            border-radius: 5px;
            border: 1px solid #ccc;
            position: relative;
            margin-bottom: 0;
        }

        .search-form .form-group input.form-control {
            padding-right: 37px;
            border: 0 none;
            background: transparent;
            box-shadow: none;
            display:block;
        }

        .search-form .form-group i.form-control-feedback {
            position: absolute;
            top: 2px;
            right: 4px;
            z-index: 2;
            display: block;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: #9c9c9c;
            left: initial;
            font-size: 14px;
        }
        
        .main-icons{
            display: flex;
            align-items: center;
        }

        .main-icons a{
            margin: 0 7px;
        }

        .main-icons a:first-child{
            margin-left: 15px;
        }

        .main-icons a:last-child{
            margin-right: 15px;
        }

        .main-icons i{
            -o-transition: all 0.218s;
            -moz-transition: all 0.218s;
            -webkit-transition: all 0.218s;
            transition: all 0.218s;
        }

        .main-icons a:hover{
            text-decoration:none;
        }

        .main-icons a:hover i{
            color: #6f1a65;
        }

        .user-login{
            width: 150px;
            display: flex;
            align-items: center;
            border-left: 1px solid #efefef;
            padding-left: 14px;
            height: 70px;
            padding-right: 15px;
        }

        .user-login img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .admin-name{
            line-height: 14px;
            padding-left: 8px;
            display: flex;
        }

/* end status bar */



/* start main content */

        .main-content{
            padding:15px 15px 0 0;
            height: calc(100% - 70px); 
        }

        /* .main-content .row{
            height: 100%;
        } */

        /* start right activity */

            .display-item{
                display: none;
            }
            
            .right-activity{
                margin-top: -15px;
                padding-right: 0px;
                padding-left: 0px;
            }

            .bg-activity{
                background-color: #f8f8f8;
                height: 100%;
                border-bottom-right-radius: 10px;
                padding: 25px 15px;
            }

            .view-user-act{
                display: flex;
                margin-bottom: 10px;
            }

            .view-user-act img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
            .user-log{
                width: 100%;
                padding-left: 10px;
            }
            
            .user-log p{
                margin-bottom: 0;
            }

            .view-btn{
                color: #c5d1e6;
                background: #eaeaea;
                border-radius: 20px;
            }

            .view-btn:hover{
                color:#4a5e80;
            }

        /* end right activity */

/* end main content */



/*\\\\\\ end index page\\\\\ */







/*\\\\\\ start add story page\\\\\ */


/* start status bar */

.statusbar .status-link a{
     color:#495057;
}

.statusbar .status-link span{
    /* font-family: Tahoma; */
    color: #bdbdbd;
}

.statusbar > span:last-child{
    color:#bdbdbd;
}

/* end status bar */


/* start progress bar */
 
  
  /* PROGRESS TRACKER */
  .progress-tracker {
    display: block;
    margin: 0;
    padding: 0;
    counter-reset: item;
    list-style-type: none;
    overflow: auto;
    position: relative;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .075em;
    padding-top: 1rem;
    /* margin: 0 auto; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }

  .progress-tracker .step:before {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 40px;
    color: #ddd;
    display: flex;
    justify-content: center;
    l: "";
    content: "";
    align-items: center;
    /* display: list-item; */
    font-size: 18px;
    line-height: 1.8em;
    /* padding: 0 0 0 13px; */
    position: absolute;
    width: 50px;
    text-align: center;
    height: 50px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    content: counter(item) " ";
    counter-increment: item;
  }
  .progress-tracker .step {
    float: left;
    list-style-position: inside;
    width: 20%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .progress-tracker .step .step-name{
    border-top: 1px solid #ededed;
    display: block;
    margin: 25px 0;
    padding: 35px 0;
    width: 100%;
    color:#ddd;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .progress-tracker .active .step-name{
      color:#6f1a65;
  }

  /* Fit design*/
   .bold {
    font-weight:bold;
    color: white;
  }

  .notbold {
    font-weight:normal;
    color: white;
  }

  .progress-tracker .fit .step-name{
    color: #6f1a65 ;
}
.progress-tracker .step.fit:before {
    color: #fff;
    background-color: #6f1a65;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.51);
    border: 1px solid #fff;
  }
  .progress-tracker .fitbg .step-name{
    color: #6f1a65 ;
}
.progress-tracker .step.fitbg:before {
    color: transparent;
    background-image: url(../images/fit/done.png);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.51);
    border: 1px solid #fff;
  }


  /*last step*/
  /* .progress-tracker .step:last-child { width: auto;} */
  
  /*active step*/
  .progress-tracker .step.active:before {
      color: #fff;
      background-color: #6f1a65;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.51);
      border: 0;
    }


    
  .progress-tracker .step.s2-active:before {
    box-shadow: none;
  }


/* end progress bar */







/* start add-items */
/* ----------------------------------- Start Step 1  ------------------------------------- */

.upload-hint{
    width: 140px;
    text-align: center;
    margin: 0 auto;
    color: #b8b8b8;
}

.add-items{
    /* text-align: center; */
    display: inline-block;
    justify-content: center;
}

.icon-upload-itm , .icon-upload-itm .new-items{
    width: 100%;
}

#pic{
    display: none;
}
   
.vid-name{
    line-height: 33px;
}

.new-items{
    cursor: pointer;
    width: 224px;
    height: 180px;
    background: #fbfbfb;
    text-align: center;
    border: 1px dashed #e7e7e7;
    padding: 0.5rem;
    position: relative;
}

.new-items .upload-icon{
    width: 70px;
    height: 55px;
}


.new-items .add-nw{
    width: 40px;
    height: 40px;
}


.add-box{
    /* margin:0.5rem; */
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.add-box video{
    height: 100%;
    width: 100%;
}

label.new-items .p-vid p {
    position: relative;
    font-family: "DroidSansArabid", Arial, Helvetica, sans-serif !important;
}



label.new-items .p-vid:hover:after {
    font-family: "Font Awesome 5 Free";
    content: "\f2ed";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.46);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease; */
}
/* ----------------------------------- end Step 1  ------------------------------------- */


/* ----------------------------------- Start Step 2  ------------------------------------- */

/* -----start questions----- */
.add-qus {
    border:1px solid #eee;
    background-color: rgb(249, 249, 249);
    width: 100%;
  }
  
.add-qus .qus-title{
      padding: 15px 1rem;
      border-bottom: 1px solid #eee;
  }

.add-Question{
    padding: 15px
}

.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: .25rem;
}


.circle-ansr{
    width: calc(2.25rem + 2px);
    height: calc(2.25rem + 2px);
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    color:#ccc;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}

.circle-ansr:hover{
    background: #39b54a;
    border-color: #39b54a;
    color:#fff;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    text-decoration: none;
}


/* -----end questions----- */


/* ------start answers------ */

.qus-added {
    border: 1px solid #eee;
    background-color: #fff;
    width: 100%;
}

.qus-added .qus-title{
    padding: 15px 1rem;
}

.qus-added .qus-title p{
    padding: 1rem;
    text-align: center;
    margin-bottom: 0;
    color: #ccc;
    font-weight: 100;
}

.answers-box{
    border: 1px solid #eee;
    background-color: #fff;
    padding: 0.5rem 0.7rem;
}

.text-ansr{
    display: inline-block;
    float: right;
    font-family: "DroidSansArabi", Arial, Helvetica, sans-serif;
    font-size: .9rem;
}

.ansr-control a{
    margin:0 5px; 
}

.ansr-control a:first-child{
    margin-left:0; 
}

.answers-box{
    margin-bottom: .6rem;
}

.answers-box:last-child{
    margin-bottom: 0;
}

.answers-box .fa-trash-alt{
    color:rgb(220, 109, 109);
}

.answers-box .fa-edit{
    color:#2569cf;
}

.ansr-control > span{
    color: #e4e4e4;
}

/* ------end answers------ */

/* ----------------------------------- end Step 2  ------------------------------------- */


/* ----------------------------------- start Step 3  ------------------------------------- */



.add-items-s3{
    text-align: center;
    /* display: inline-block; */
    /* justify-content: center; */
}

.chara-box{
    border: 1px solid #e7e7e7;
    padding: .5rem;
}

.cover-character{
    /* max-width: 244px; */
    width: auto;
    height: 150px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-character:after{
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.48);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.cover-character img{
    position: relative;
    z-index: 1;
    width: 130px;
    height: 130px;
}

.ture-circle , .false-circle{
    font-size: 1.2rem !important;
    margin-right: .5rem;
}

.ture-circle{
    color: #39b54a;
}


.false-circle{
    color: #999999;
}

span.ansr{
    line-height: 9px;
    vertical-align: text-top;
}

/* ----------------------------------- end Step 3  ------------------------------------- */




/* end add-items */






/*\\\\\\ end add story page\\\\\ */


/*\\\\\\ start footer \\\\\ */

footer p{
    font-size: .8rem;
    color: white;
}

footer.footer-style p{
    color: #4a5e80;
}


/*\\\\\\ end footer \\\\\ */