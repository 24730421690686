#saveButton{
    width: 110px;
    color: white;
    float: right;
    background: blue;
}

#cancelButton{
    color: white;
    float: right;
    background: black;
  }
