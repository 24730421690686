.dashboard {
}
  
.statistics-section {
    display: flex;
    justify-content: space-between;
}
  
.stat-card {
    background-color: #c9f2d8;
    padding: 15px;
    border-radius: 8px;
    flex: 1;
    margin-right: 10px;
    text-align: left; 
}

.stat-card h3 {
    margin: 0;
    font-size: 10px; /* Smaller label font size */
    color: #444;
}

.stat-card p {
    font-size: 28px; /* Larger number font size */
    color: #333;
    font-weight: bold; /* Bold numbers */
    margin: 0;
}


.total-users {
    background-color: #c9f2d8; 
}

.active-users {
    background-color: #c0e4b4; 
}

.remaining-users {
    background-color: #5cb4bb; 
}
.content-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
  
.content-views {
    flex: 0.7; 
    margin-right: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.top-series {
    flex: 3; 
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
  
.pie-legend {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px; 
}

.legend-color {
    width: 16px; 
    height: 8px; 
    border-radius: 4px; 
    margin-right: 10px; 
}
  
.top-series h3 {
    margin-bottom: 10px;
}
  
.tabs-dash {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background: #cac4d0;
    border-radius: 30px;
    box-sizing: border-box;
    padding-bottom: 0;

}
  
.tab-button {
    padding: 5px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: transparent;
    color: #333;
}
  
.tab-button.active,
.tab-button:hover {
    background-color: #6a1c66;
    color: white;
    border-radius: 30px;
}
  
.progress-bars {
    margin-top: 10px;
}
  
.progress-bar-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}
  
.progress-bar-name {
    flex: 1;
}
  
.progress-bar {
    flex: 5;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin: 0 10px;
    position: relative;
}
  
.progress-fill {
    height: 100%;
    border-radius: 5px;
    background-color: #7D3C98;
}
  
.progress-bar-value {
    flex: 1;
    text-align: right;
}

/*Edit the below for global margin width and min vertical height of the panel*/

.container-body > .row > div.col-3 {max-width: 250px}

.container-body > .row > div.col-9 {max-width: calc(100% - 250px); 
 flex-grow: 1;}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1400px;
    }
}

.container-body > .row {
    min-height: 100vh;
}
