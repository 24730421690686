
.circles-labels{
  margin-top: -10px
}

.label{
  color:gray
}

.circle1{
  display: inline-block;
  height: 50px;
  width: 50px;
  background-color: #7d276d;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: small;
  cursor: context-menu;
  /* box-shadow: 0px 0px 27px 0px rgba(58, 58, 58, 0.1); */
  /* border: 17px; */
  border: 2px solid #fff;
  box-shadow: 0px 0px 6px 0px rgba(2, 2, 2, 0.4);
  line-height: 49px;
  text-align: center;
  border-color: rgb(213, 213, 213);
}

.circle2{
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-left: 40px;
  text-align: center;
  color: gray;
  font-size: small;
  cursor: context-menu;
  /* box-shadow: 0px 0px 27px 0px rgba(58, 58, 58, 0.1); */
  /* border: 17px; */
  border: 2px solid #fff;
  /*box-shadow: 0px 0px 6px 0px rgba(2, 
  2, 2, 0.4);*/
  line-height: 49px;
  text-align: center;
  border-color: rgb(213, 213, 213);
}

.circle-label{

  background-color: white;
  display: inline;
  text-align: center;
  color: gray;
  margin-left: 20px;
  /* box-shadow: 0px 0px 27px 0px rgba(58, 58, 58, 0.1); */
  /* border: 17px; */
  /*box-shadow: 0px 0px 6px 0px rgba(2, 
  2, 2, 0.4);*/
  text-align: center;
  border-color: rgb(213, 213, 213);
}

#active-label
{
  color: purple
}

     /* Start  Video Styling*/
.drop-video{
  border: .5px ridge grey !important;
}

.paragrah-video{
  margin-top: -80px;
  padding-bottom: 70px;
}
      /* End  Video Styling*/

.drop-image
{
  border: .5px ridge gray !important;
  text-align: center;
  background-color: #F5F5F5;
  line-height: 96px;
  height: 150px;
  color: gray;
}


.pDrop-image
{
  margin-top: -28px;

}

#episode-radio{
  margin-left: 40px
}

#episode_description{
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
  transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
}

#episode_series_id{
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.required_input{
  color: red;
  font-size: 40px;
  }