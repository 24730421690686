.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.assign-students-button, .date-range-dropdown .ui.selection.dropdown {
    background-color: #5E2467;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

/* Styling for the dropdown */
.date-range-dropdown .ui.selection.dropdown {
    background-color: #e0e0e0; /* Dropdown background */
    color: #5E2467; /* Text color */
    border-radius: 8px; /* Make sure it matches other buttons */
}

.date-range-dropdown .ui.selection.dropdown .menu {
    background-color: #e0e0e0; /* Menu background when dropdown is opened */
    color: #5E2467; /* Text color in dropdown options */
}

.date-range-dropdown .ui.selection.dropdown .menu .item {
    color: #5E2467; /* Text color for each dropdown item */
}

.date-range-dropdown .ui.selection.dropdown:hover {
    background-color: #d3d3d3; /* Slight hover effect on dropdown */
}

/* Optional: Add hover effect for dropdown items */
.date-range-dropdown .ui.selection.dropdown .menu .item:hover {
    background-color: #cfcfcf; /* Hover background for dropdown items */
}

.tabs-dropdown-container {
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 100%; 
    position: relative;
}

.tabs-switch {
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    background-color: transparent; 
    border: none;
    z-index: 1;
}

.tabs-switch button {
    padding: 10px 30px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: -10px; 
    z-index: 1;
    min-width: 150px;
}

.tabs-switch button.active-tab {
    background-color: #5E2467;
    color: white;
    z-index: 2;
    border-radius: 50px;
}

.tabs-switch button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.tabs-switch button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 0; 
}

.tabs-switch::before {
    display: none; 
}

.date-range-dropdown {
    position: absolute;
    right: 0; 
    top: 50%;
    transform: translateY(-50%); 
    z-index: 10;
}

.ui.selection.dropdown {
    z-index: 9999; 
}

.mt-4 {
    margin-top: 20px;
}

.name-cell {
    display: flex;
    align-items: center;
}

.profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.filter-input, .filter-dropdown {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px; 
}