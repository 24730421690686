
@font-face {
    font-family: DINNextLTArabicRegular;
    src: url("../fonts/DINNextLTArabicBold.ttf");
    src: url("../fonts/DINNextLTArabicRegular.otf");
    src: url("../fonts/DINNextLTArabicRegular.woff");
}
* {
  font-family: DINNextLTArabicRegular,'Cairo', Tahoma, Arial, Helvetica, sans-serif

}

#font{    
  font-family: DINNextLTArabicRegular,'Cairo', Tahoma, Arial, Helvetica, sans-serif

}

/* Start Logo */
.logo-container {
    padding: 30px 0px;
    padding-bottom: 0;
}
.logo-container .logo {
    height: 95px;
    width: 95px;
}
.logo-container .logo img {
    max-width: 100%;
    max-height: 100%;
}
/* End Logo */

.white{
    color: white;
}

.dot {
    height: 30px;
    width: 30px;
    background-color:black;
    border-radius: 50%;
    display: inline-block;
  }

.jeel-fit{
          /* background-color: #e9ecef; */
    background-image: url('../images/fit/bg@3x.png');
    background-size: cover;
    background-position: center;
    position: relative;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;

  }

  @media (max-width:779px) {
    
    .jeel-fit{
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
        
    }

  }

  .jeel-fit .fit-about-text .title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 1.4;
    text-align: right;
}

.text-rtl{
    text-align: right;
    direction: rtl;
    float: right;
}

/* .submit-btn{
    width: 50%;
    height: 50%;
    background-color: #ffad4d;
    display: block;
    margin: auto;
    min-width: 80%;
    border-radius: 50px;
    padding: 10px 20px;
    color: #FFF;
} */
/* 
.form-header{
    float: right;
    display: flex;
    justify-content: space-around;
    padding: 25px 0px;
} */

.form-control:focus, .form-control:active {
    background: transparent;
}

.filed{
    border: none;
    border-bottom: solid 2px #ced4da;
    float: right;
    margin-bottom: 10px;
    text-align: right;
     color: black !important;

}
.filed, .form-control:disabled, .form-control[readonly] {
    background-color: white;
    opacity: 1;
}

.color-font{
    color: #ffad4d;
}

.policies{
    float: right;
    margin-bottom: 20px;
    margin-top: 10px;
}

.another-ways{
    color: #ffad4d;
    text-align: center;
}

.img{
    text-align: center;
    display:block;
    margin:auto;
}

.margin{
    margin-top: 10px;
    margin-bottom: 5px;;
}

.completeAccount{
    color: #ffad4d; 
    text-align: center;
    display:block;
    margin:auto;
}

.cancel{
    color:gray;
}

.download{
background-color: black;}

.phone-card, .telecom-phone-card{
    border-radius: 13px;
    box-shadow: 8px 9px 30px 0 rgba(0, 0, 0, 0.09);
    background-color: #FFF;
    max-width: 3200px;
    -webkit-margin-start: auto;
    margin-inline-start: auto;
    padding: 20px;

    text-align: right;
    /* max-width: 500px; */
    width: 320px;
    /* border: 1px solid rgba(222,223,224,0.3); */
    
    display:block;
    margin:auto;
    background-color: white;

}
.phone-section{
    margin-top: -20px;
    /* top: 150px;
    right: 45%; */
    /* border: 5px solid red; */
    /* position: absolute; */
}

.marginAuto{
    margin: 0 auto !important;
}
#right-lift{
    display: block;
    direction:ltr !important;

}
#phone{
    display: block;
    direction:ltr !important;
    margin-left: 25%;
}

#fit_space{
    margin-right: 10%;
}

@media (max-width:1000px) {
    #phone{
        margin: 0 auto;
        /* margin-left: 0px; */
    }
    .phone-section{
        margin: 0 auto;
    
    }
  }
  
  @media (max-width:779px) {
    
    .phone-section{
        /* margin-left: 15%; */
        margin-left: 0 auto;
    
    }
    .phone-card{
        margin-top: -40px;
    }
    #fit_space{
        margin-right: 0px;
    }
  }

  @media (max-width:400px) {
    #phone{
        margin-left: 0px !important;
    }
    .phone-section{
        margin: 0px !important;
    
    }
    .marginAutoCol{
        margin: 0px;
    }
    .phone-card{
        margin-top: -40px;
    }
    #fit_space{
        margin-right: 0px;
    }
  }

  .react-tel-input .country-list{
     margin-left: -250px;
  }

  .react-tel-input .flag-dropdown{
    right: 1px;

    background-color : transparent;
    border: none;
  }

.phoneNum .react-tel-input .form-control    {
    font-family: DINNextLTArabicRegular,'Cairo', Tahoma, Arial, Helvetica, sans-serif;

    border: none;
    border-bottom: solid 1px #ced4da;
    width: 85%;

    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    text-align: right;
    color: #76166b !important;
    
}

.form-control:focus {
    border-color: #ffad4d;
    box-shadow: none;
}
.phone-section .form-container {
    
    margin-inline-start: auto;
}

.buttons .form-container {
    border-radius: 13px;
    box-shadow: 8px 9px 30px 0 rgba(0, 0, 0, 0.09);
    background-color: #FFF;

    max-width: 500px;
    margin-inline-start: auto;
    padding: 20px;
}
@media (max-width: 768px) {
    .buttons .form-container {
        max-width: 100%;
        margin: 0;
    }
    .phone-section .form-container {
        max-width: 100%;
    }
}



.buttons .form-container .toggle-btn.active {
    color: #ffad4d;
}
.buttons .form-container .toggle-btn {
    background: none;
    position: relative;
    padding: 0;
    font-weight: bold;
    outline: none;
    box-shadow: none;
    font-size: 18px;
    font-family: DINNextLTArabicRegular,'Cairo', Tahoma, Arial, Helvetica, sans-serif

}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.buttons .form-container .toggle-btn.active:before,
 .buttons .form-container .toggle-btn:hover:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -5px;
    width: 100%;
    height: 3px;
    background-color: #ffad4d;
}


.buttons .form-container .btn-container {
    display: flex;
    justify-content: space-around;
    padding: 25px 0px;
}

.buttons .form-container .form-group {
    padding: 0.375rem 1.3rem;   
    height: 50px;
    opacity: 0.5;
    border-radius: 25px;
    border: solid 1px #9e9e9e;
    background-color: #ffffff;
    
}

.buttons .form-container .input-container .password-icon {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0, -50%);
    cursor: pointer;
}

.buttons .form-container .input-container .password-icon .hide {
    display: none;
}
.buttons .form-container input,
.buttons .form-container select {
    border: none;
    box-shadow: none;

    background-color: #FFF;
    padding: 0;
    /* color:black !important;
    font-weight: bold;*/

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9e9e9e;
}

.buttons .form-container form button {
   margin-top: 5px;
    background-color: #ffad4d;
    color: #FFF;
    height: 50px;
    border-radius: 25px;
    box-shadow: none;
    outline: none;
    border: none;
    font-size: 17px;
}

.buttons .form-container form button:disabled {
    background-color: #ced4da;  
}

.buttons .form-container input{
    color: black;
  }

.buttons .form-container form .forgot-pw {
    text-align: end;
}
.buttons .form-container form .forgot-pw a {
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: color .3s ease-in-out,
                border-color .3s ease-in-out;
    display: inline-block;
    font-size: 16px;

}
.buttons .form-container form .forgot-pw a:hover {
    color: #ffad4d;
    border-color: #ffad4d;
}
.buttons .another-ways {
    text-align: center;
    font-size: 12px;
    padding: 0px 20px;
}
.buttons .another-ways .text {
    background-color: #fff1e1;
    padding: 10px;
    border-radius: 5px;
}
.buttons .another-ways .text p {
    color: #957b5e;
    margin-bottom: 5px;
}
.buttons .another-ways .text .sign-way,
.buttons .another-ways .text .sign-way a {
    font-size: 18px;
    color: #ffad4d;
    text-decoration: none;
}
.buttons .another-ways .text .sign-way a {
    border-bottom: 1px solid transparent;
    transition: border-color .3s ease-in-out;
}
.buttons .another-ways .text .sign-way a:hover {
    border-color: #ffad4d;
}
/* End Promo Code */


  /*********************************************/
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}


#border{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
   
    padding-top: 30px;
    float: none;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 8px 9px 30px 0 rgba(0, 0, 0, 0.09);
    background-color: #FFF;
  }
                     /* download section*/

.download-app {
    position: relative;
    overflow: hidden;
    height: 250px;
    color: #FFF;
}
@media (max-width: 1199px) {
    .download-app {
        background-color: #76166b;
        height: fit-content;
    }
}
@media (min-width: 1200px) {
    .download-btns {
        position: absolute;
        bottom: 55px;
        right: 50px;
        width: 60%;
    }
}
.download-btn {
    background: none;
    outline: none;
    padding: none;
    border: none;
    cursor: pointer;
    height: 60px;
    width: 150px;
    margin-top: 30px;
}
.download-btn img {
    width: 100%;
    height: 100%;
}
.download-app .download-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}
.download-app .download-bg img {
    width: 100%;
    height: 100%;
}
                     /*end download section*/

.password-eye{
    position: absolute;
    left: auto;
    left: 10px;
    text-indent: 32px;
    top:10px;
}