.react-tabs__tab{
    display: inline-block;
    border: 1px solid #ddd;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    background-color: #eee;
    padding: 1rem 2rem;
    cursor: pointer;
}

.react-tabs__tab:hover {

    display: inline-block;
    border: 1px solid #64165A;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    background-color: white;
    padding: 1rem 2rem;
    cursor: pointer;

}
.react-tabs__tab--selected
{
    display: inline-block;
    border: 1px solid #64165A;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    background-color:  white;
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: 0px;
}

.react-tabs__tab-list
{
    border-bottom: 1px solid #aaa;
    margin: 0 0 0px;
    padding: 0;
    background-color: #eee;
}