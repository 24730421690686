#episode_view{
    float: right;
    background:rgb(0,191,255);
    color: white;
    width: 100px;
}

span.ansr{
    line-height: 9px;
    vertical-align: text-top;
    margin-left: .5rem;
}

div.ansr{
    display: inline-block;
    position: relative;
}

div.ansr img {
    display: inline-block;
    width: 88px;
    height: 60px;
    border: 3px solid #545454;
    margin-top: .5rem;
}


#evaluationtd {
    word-break: break-all;
  }

#shorthr{
    width: 85%;
    border-style: dashed;
}