/*
	dialog2 {
        position: absolute;
        width: 200px;
        padding: 10px;
        background-color: #f1f1f1;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        outline: none;
      }

      dialog::backdrop {
        background: rgba(0, 0, 0, 0.4);
      }

      button {
        padding: 10px 20px;
        margin-right: 20px;
        color: white;
        background-color: #007bff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.25s ease-out;
      }

      button:hover {
        background-color: #0056b3;
      }

      #dialog2 {
        padding: 20px;
        background-color: #fff;
        border: 1px solid #bbb;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
      */

      #openDlg {
        padding: 10px 20px;
        margin-right: 20px;
        color: white;
        background-color: purple;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.25s ease-out;
      }
      button:hover {
        background-color: #0056b3;
      }

	  #closeDialogHeader{
	   	width:50px;
        height:50px;
	  }	
	  .dlgContents{
	  	padding:40px;
	  	background-color : #32b3b3;
	  }	

	  #dialogBox::backdrop {
        /*opacity: 1;
        background-color: rgb(25, 25, 170);
        backdrop-filter: blur(5px);*/
        background: rgba(0, 0, 0, 0.4);
      }
      #dialogBox {
        box-shadow: 5px 10px #888888;
        border-radius: 20px;
        border: none;
        margin-left:30vw;
        margin-top:10vh;
        width:40vw;
        height:70vh;
        /*background-color : #7b79ef;*/
      }
      #dialogBox header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      #deleteButton {
        background-color: red;
        border: none;
        color: white;
        padding: 12px 32px;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
      }
      #closeDialogFooter {
        background-color: purple;
        border: none;
        color: white;
        padding: 12px 32px;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
      }
           