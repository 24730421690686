.detailed-activity-report {

}

.report-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.date-range {
    display: flex;
    justify-content: flex-start;
    gap: 10px; 
    margin-bottom: 20px;
}

.date-input {
    font-size: 0.9rem;
    color: #555;
    background-color: #f3f3f3;
    padding: 8px 12px;
    border-radius: 8px;
}


.activity-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.activity-header h3 {
    font-size: 1.2rem;
    font-weight: bold;
}

.activity-count {
    font-size: 0.9rem;
    color: #7a1b5c;
}

.activity-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.activity-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.activityy-item {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.activity-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 15px;
}

.activity-details {
    flex: 1;
}

.activity-name {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.activity-evaluation {
    font-size: 0.9rem;
    color: #333;
}

.evaluation-score {
    color: #7a1b5c;
    font-weight: bold;
    margin-left: 5px;
}

.details-but {
    background-color: #7a1b5c;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.details-but :hover {
}
