/* the comman Css of viewEpisode and PublishEpisode
that conatains Video , image and text */


/* Start Video Palyer Style*/

.video_section {
    border: 7px solid black;
    text-align: center;
    background-color: #F5F5F5;
    /* line-height: 250px; */
    color: gray;
}

.video-react-controls-enabled.video-react-paused.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react,
.video-react-controls-enabled.video-react-paused.video-react-fluid.video-react-user-active.video-react-workinghover.video-react,
.video-react-controls-enabled.video-react-has-started.video-react-paused.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react,
.video-react-controls-enabled.video-react-has-started.video-react-playing.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react,
.video-react-controls-enabled.video-react-has-started.video-react-playing.video-react-fluid.video-react-user-active.video-react-workinghover.video-react {
    height: 303px !important;
    /* padding:0 !important; */
}

button.video-react-big-play-button.video-react-big-play-button-left {
    margin-top: 25%;
    margin-left: 42%;
    background-color: purple;
    border-radius: 50%;
    width: 70px;
    height: 50px; 
}

/** End Video Palyer Style**/


/** Start image Style**/

.image_Preview {
    text-align: center;
    line-height: 96px;
    height: 150px;
    color: gray;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
    object-fit: scale-down;
}

/** Start Table Labels Style**/

.episode_label {
    color: gray;
    padding-bottom: 20px;
}

th.episode_label {
    width: 220px;
    text-align: left;
    font-size: fit-width;
}
/** End Table Labels Style**/

/* Episode_Question Activity */
#episode_Question_Row {
    /* border: 3px solid rebeccapurple; */
    margin-left: 10px;
}

.question_label {
    color: gray;
    font-weight: bold;
}

.question_label
{
    margin-top: 0.5rem;
}

#questionHR {
    width: 85%;
    border: 1px solid rgba(34, 36, 38, .15);
}

/*Question_Container Text and Answers*/
.question_text{
    color: gray;
}

.question_container{
    /* border: 2px solid red; */
    margin-bottom: 30px;
    margin-right: 8%;
}

.episode_question_img{
    margin-right: 10px;
    text-align: center;
    line-height: 96px;
    height: 100px;
    color: gray;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
}

/****************** Start for image preview in Add & Edit Series a***************************/
.new-items {
    width: 100% !important;
}

.image_Preview_edited {
    text-align: center;
    line-height: 96px;
    width: 100%;
    color: gray;
    border-radius: .28571429rem;
    transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
    object-fit: scale-down;
}

#file_input{
    width: 100%;
}

.img-edit{
    border: 1px solid #ddd;
    text-align: center;
    min-height: 113px;
    display: flex;
    width: 100%;
}

.image_Preview {
    width: 100%;}

    .question .img-edit{
        height: 113px;
        margin-bottom: 1rem;
    }

    .question .image_Preview {
        height: 100%;}

/****************** End for image preview in Add & Edit Series a***************************/

