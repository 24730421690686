.data-container{
    border: 1px solid rgba(0,0,0,.1);
}

.seriesCardButtons
{
    background-color: white;
    color: black;
    border: 1px solid gray;
    border-radius: 7px;
    font-size: 14px;
    padding: 5px 10px;
    float: right;
    margin-right:14px;
}
.card-title{
    font-weight: bold; 
}
.date , .time{
    color: gray;
}
.card{
    background-color: white;
    
}