#editSeriesCard{
    border: none;
    height: 150px;
    margin-top: 10px;
}

.title_buttons_container{
    /* border: 5px solid green; */
    margin-top: 25px;
}

#episode-header{
    background-color:rgba(34,36,38,.10);
   
}

#editEpisodeCard{
    margin-left: 30px;
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
}

#search-container{
    /* border: 5px solid green; */
    margin-left: 70%;
    margin-top: -25px;
    margin-bottom: -25px;
}

/* #search{
    /* border: 5px solid red; */
    /* float: right; */
    /* margin-left: 500px; */
 /* } */ 

