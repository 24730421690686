.general-setting-header
{
    border-radius: 3px;
    height: 50px;
    background-color: #F5F5F5;
    line-height: 50px;
    padding-left: 10px;
    color:   #4d004d
    ;
}
.general-setting-form .form-control {
    width: 70% 
}

.handleWidth .general-setting-form .form-control
{
    width: 100%
}

.general-setting-form .nextButton 
{
    /* color: #005000; */
    float: right;
}

.general-setting-video .video-react .video-react-big-play-button
{
    margin-top: 55%;
    margin-left: 28%;
    background-color: purple;
    border-radius: 50%;
    width: 70px;
    height: 50px;
}

.general-setting-form .legend
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    /* max-width: 100%; */
    /* padding: 0; */
    width: auto;
    padding: 10px;
    white-space: normal;
    color:  #4d004d
    ;
    font-size: 16px;
    font-weight: 600;
}

.general-setting-form .fieldset
{
    border: 1px solid lightgray;
    padding: .35em .75em .625em;
    margin: 10px;
    /* width: inherit; */
    border-radius: 5px;
    padding: -54px;
    /* border-bottom: none; */
}
.general-setting-form #contentSettingbutton{
    float: right;
    width: 100px;
    border: none;
    border-radius: unset;
    color: white;
    background-color: #4d004d;
}

.qc-div
{
    border: solid 1px lightgray;
}

#blabla
{
    margin: 10px;

}