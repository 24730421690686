
@font-face {
    font-family: DroidSansArabi;
    src: url("../../webfonts/DroidSansArabi.ttf");
    }

@font-face {
    font-family: Raleway;
    src: url("../../webfonts/Raleway/Raleway-Regular.ttf");
    }




*{
    font-family: "Raleway",Arial, Helvetica, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


html{
    min-height: 100%;
}

/* body{
    background-image: linear-gradient(#59cef1, #55dedb);
    background-image: -webkit-linear-gradient(#59cef1, #55dedb);
    background-image: -moz-linear-gradient(#59cef1, #55dedb);
    font-size: 0.9rem;
    color: #353535;
} */

/* ///--- start custom scrollbar---/// */

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ededed; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6f1a65; 
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #65155c; 
  }
  
/* ///--- end custom scrollbar---/// */




.left-main-body{
    background: white;
    padding-bottom: 1rem;
    border-bottom-right-radius: 10px;
}

/* .left-main-body .card-body{
    background: white;
} */

.h7{
    font-size: .8rem;
}

.small-font{
    font-size: .8rem ;
}

.x-small-font{
    font-size: .7rem;
}

.main-color{
    color:#6f1a65 !important;
}

.gray-color{
    color: #9c9c9c;
}

.secondary{
    color:#4a5e80
}

.language-btn{
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-block;
    background: #831c77;
    border-radius: 50%;
    line-height: 30px;
    color: white;
}

.logout-btn {
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #831c77;
    border-radius: 50%;
    line-height: 0;
    color: white;
    padding: 8px;
}

.logout-btn img {
    width: 100%;
}

.language-btn:hover,.logout-btn:hover{
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    color: white;
    background-color: #79176d;
    text-decoration: none;
}

.main-logo{
    width: 73px;
}

.last-edit{
    color: #4a5e80;
    line-height: 30px;
}




.section-title{
    position: relative;
    display: flex;
}

.section-title span{
    position: absolute;
    left: 0;
    background: white;

}

.section-title:before{
    position: absolute;
    content: " ";
    display: flex;
    justify-content: center;
    border: 1px solid #ddd;
    width: 100%;
}


  div.hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
    text-align: left;
    line-height: 0px !important;
  }
  
 .hr-title{
    line-height: 0px !important;
    background: white;
    padding-right: 1rem;
 }
  
.main-title-container > .main-title{
    display: inline-block;
    line-height: 24px;
}





.btn.btn-nxt , .btn.btn-bck, .btn.btn-cls , .btn-suc , .btn-pry , .btn-del , .add-btn{
    padding:0 1.5rem;
}



.add-btn {
    /* background-color: #4d90fe; */
    background-color: #831c77;
}

.btn.btn-nxt , .btn.btn-bck, .btn.btn-cls , .btn-suc , .btn-pry , .btn-del , .add-btn{ 
    font-weight: 100;
    font-size: 14px !important;
    height: 40px !important;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
    /* margin: 5px 0; */
    padding: .5rem 1rem !important;
}

.btn-suc{
    /* height: 38px !important; */
}

.btn.btn-nxt:hover, .btn.btn-bck:hover,  .add-btn:hover, 
.btn.btn-nxt:active, .btn.btn-bck:active,  .add-btn:active, 
.btn.btn-nxt:focus, .btn.btn-bck:focus, .add-btn:focus {
    background-color: #611358;
}

.btn.btn-nxt:not(:disabled):not(.disabled).active, .btn.btn-bck:not(:disabled):not(.disabled).active,.add-btn:not(:disabled):not(.disabled).active,
.btn.btn-nxt:not(:disabled):not(.disabled):active, .btn.btn-bck:not(:disabled):not(.disabled):active,.add-btn:not(:disabled):not(.disabled):active,
.show>.btn.btn-nxt.dropdown-toggle , .show>.btn.btn-bck.dropdown-toggle , .show>.btn.add-btn.dropdown-toggle{
    background-color: #611358;
    border-color: #611358;
}

.btn.btn-cls:hover,
.btn.btn-cls:active,
.btn.btn-cls:focus {
    background-color: #353535;
}

.btn.btn-cls:not(:disabled):not(.disabled).active,
.btn.btn-cls:not(:disabled):not(.disabled):active,
.show>.btn.btn-cls.dropdown-toggle{
    background-color: #353535;
    border-color: #353535;
}


.btn.btn-nxt {
    /*background-color: #4d90fe; */
    background-color: #831c77;
}


.btn.btn-bck {
    /*background-color: #4d90fe; */
    background-color: #ac50a1;
}


.btn.btn-cls {
    /*background-color: #4d90fe; */
    background-color: #252525;
}




.btn-add{
    padding: .7rem;
    border: 1px solid #ddd;
    background: rgb(232, 232, 232);
    height: auto !important;
    font-size: 0.88rem;
    color: #212529;
}

.btn-add:hover,
.btn-add:active,
.btn-add:focus {
    background-color: rgb(189, 189, 189);
    border-color: rgb(189, 189, 189);
    color: #212529;
}

.btn-add:not(:disabled):not(.disabled).active,
.btn-add:not(:disabled):not(.disabled):active,
.show>.btn.btn-add{
    background-color: rgb(189, 189, 189);
    border-color: rgb(189, 189, 189);
    color: #212529;
}






.add-ansrs-btn{
    background-color: #2762bc;
}





.btn-pry{
    /* padding: .7rem; */
    border: 1px solid #16ade5;
    background: #16ade5;
    /* height: auto !important;
    font-size: 0.88rem;
    border-radius: .3rem; */
}

.btn-pry:hover,
.btn-pry:active,
.btn-pry:focus {
    background-color: #129ed1;
    border-color: #129ed1;

}

.btn-pry:not(:disabled):not(.disabled).active,
.btn-pry:not(:disabled):not(.disabled):active,
.show>.btn.btn-pry{
    background-color: #129ed1;
    border-color: #129ed1;
}




.btn-del{
    /* padding: .7rem; */
    border: 1px solid rgb(210, 55, 55);
    background: rgb(210, 55, 55);
    /* height: auto !important;
    font-size: 0.88rem; */
}

.btn-del:hover,
.btn-del:active,
.btn-del:focus {
    background-color: rgb(185, 45, 45);
    border-color: rgb(185, 45, 45);

}

.btn-del:not(:disabled):not(.disabled).active,
.btn-del:not(:disabled):not(.disabled):active,
.show>.btn.btn-del{
    background-color: rgb(185, 45, 45);
    border-color: rgb(185, 45, 45);
}





.btn-outline-secondary.focus, .btn-outline-secondary:focus {
        box-shadow: none !important;
}



button.btn.btn-outline-secondary{
    height: 38px;
    border-color: #ced4da;
}



button.btn.btn-outline-secondary i{
    color:#6c757d;
}

button.btn.btn-outline-secondary:hover , .btn-outline-secondary:not(:disabled):not(.disabled).active{
    background: rgb(220, 109, 109);
    color:white;
    border-color: rgb(220, 109, 109);
}

button.btn.btn-outline-secondary:hover i{
    color: white;
}


.form-control:focus {
    border-color: #ced4da;;
    box-shadow: none;
}



/* ------ start alert-success------- */

.alert-box{
    display: flex;
    justify-content: center;
}

.alert-success{
    position: absolute;
    margin: 0 auto;
    top: 2rem;
    z-index: 5;
}

.alert-success {
    border-color: #6cb97e;
    -moz-box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.2);
}

/* ------ end alert-success------- */



/* ---------start custom table ------ */

.responsive-table{
    overflow-x:auto;
}

.custm-table thead tr{
    background: #80497a;
    color: white;
}

.custm-table .custom-control-label {
    position: relative;
    margin-bottom: 15px;
    margin-left: -3px;
}



.responsive-table .navigation{
    display: flex !important;
    justify-content: center !important;
    align-items: baseline !important;
}

.t-user{
    display: inline-block;
    margin-left: 1rem;
}

.navigation .page-link {
    color: #7b7b7b;
}


.navigation .page-item.active .page-link {
    background-color: #adadad;
    border-color: #adadad;
}

.custm-table a.key , .custm-table a.user-lock , .custm-table a.trash{
    font-size: 1rem;
    margin: 0.3rem;
    color: #acacac;
}

.custm-table a.key:hover i , .custm-table a.user-lock:hover i , .custm-table a.trash:hover i{
    color: #6f1a65;
}

.custm-table a.key.active i , .custm-table a.user-lock.active i , .custm-table a.trash.active i{
    color: #6f1a65;
}

/* start checkbox */
.custm-table .custom-control{
    min-height: auto;
}

.custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before ,.custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before , .custm-table .custom-control-input:checked~.custom-control-label::before{
    background-color: #ad5fa4;
}

.custm-table .custom-control-input:focus~.custom-control-label::before{
    box-shadow: none;
}

.custm-table .custom-control-input {
    position: unset;
    z-index: auto;
    opacity: 0;
}
/* end checkbox */

/* ---------end custom table ------ */


/* ---------start custom-checkbox ------ */

.custom-control{
    min-height: auto;
}

.custom-control-label::before {
    top: 0.1rem;
}
.custom-control-label::after {
    top: .1rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before ,.custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before , .custm-table .custom-control-input:checked~.custom-control-label::before{
    background-color: #ad5fa4;
}

.custom-control-input:focus~.custom-control-label::before{
    box-shadow: none;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #ad5fa4;
}

.custom-control-input {
    /* position: unset; */
    z-index: auto;
    opacity: 0;
}

/* .custm-table .custom-control {
    padding-left: .75rem;
} */

.custom-control-label::before {
    background-color: #cacaca;
}

.l-notification .custom-control.custom-checkbox{
    display: inline-block;
}

.l-notification .small-label{
margin-bottom: .5rem;
}

.l-notification .custom-control-label::before {
    position: absolute;
    top: 0.14rem;
}

.l-notification .custom-control-label::after {
    top: .14rem;
}

/* ---------end custom-checkbox ------ */

/* ---------start custom-redio button ------ */

.custom-radio .custom-control-label{
    cursor: pointer !important;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #6f1a65;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #6f1a65;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before{
    background-color: #6f1a65;  
}

.custom-control-input:focus~.custom-control-label::before{
    box-shadow: none;
}


/* ---------end custom-redio button ------ */



/* ---------start div vertical center ------ */

.outer-middle-container {
    display: table;
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.middle-container {
    display: table-cell;
    vertical-align: middle;
}


/* ---------end div vertical center ------ */


.adduser-checkbox{
    position: relative;
    left: calc(1.5rem + 0px);
}


.adduser-checkbox .custom-control-input:checked~.custom-control-label::before, .custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before, .custm-table .custom-control-input:checked~.custom-control-label::before {
    background-color: #6f1a65;
}




/* ///////////// */
/* 

  .toggle {
    position:relative;
    display:inline-block;
    width:40px;
    height:60px;
    background-color:#bbb;
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    border-radius:4px;
    text-align:center;
  }
  
  .toggle input {
    width:100%;
    height:100%;
    margin:0 0;
    padding:0 0;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:2;
    cursor:pointer;
    opacity:0;
    filter:alpha(opacity=0);
  }
  
  .toggle label {
    margin: 0;
    display:block;
    position:absolute;
    top:1px;
    right:1px;
    bottom:1px;
    left:1px;
    background-image:-webkit-linear-gradient(top,#fff 0%,#ddd 50%,#fff 50%,#eee 100%);
    background-image:-moz-linear-gradient(top,#fff 0%,#ddd 50%,#fff 50%,#eee 100%);
    background-image:-ms-linear-gradient(top,#fff 0%,#ddd 50%,#fff 50%,#eee 100%);
    background-image:-o-linear-gradient(top,#fff 0%,#ddd 50%,#fff 50%,#eee 100%);
    background-image:linear-gradient(top,#fff 0%,#ddd 50%,#fff 50%,#eee 100%);
    -webkit-box-shadow:0 2px 3px rgba(0,0,0,0.4),
      inset 0 -1px 1px #888,
      inset 0 -5px 1px #bbb,
      inset 0 -6px 0 white;
    -moz-box-shadow:0 2px 3px rgba(0,0,0,0.4),
      inset 0 -1px 1px #888,
      inset 0 -5px 1px #bbb,
      inset 0 -6px 0 white;
    box-shadow:0 2px 3px rgba(0,0,0,0.4),
      inset 0 -1px 1px #888,
      inset 0 -5px 1px #bbb,
      inset 0 -6px 0 white;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    border-radius:3px;
    font:normal 11px Arial,Sans-Serif;
    color:#666;
    text-shadow:0 1px 0 white;
    cursor:text;
  }
  
  .toggle label:before {
    content:"or";
    position:absolute;
    top:6px;
    right:0;
    left:0;
    z-index:4;
  }
  
  .toggle label:after {
    content:"and";
    position:absolute;
    right:0;
    bottom:11px;
    left:0;
    color:#666;
    text-shadow:0 -1px 0 #eee;
  }
  
  .toggle input:checked + label {
    background-image:-webkit-linear-gradient(top,#eee 0%,#ccc 50%,#fff 50%,#eee 100%);
    background-image:-moz-linear-gradient(top,#eee 0%,#ccc 50%,#fff 50%,#eee 100%);
    background-image:-ms-linear-gradient(top,#eee 0%,#ccc 50%,#fff 50%,#eee 100%);
    background-image:-o-linear-gradient(top,#eee 0%,#ccc 50%,#fff 50%,#eee 100%);
    background-image:linear-gradient(top,#eee 0%,#ccc 50%,#fff 50%,#eee 100%);
    -webkit-box-shadow:0 0 1px rgba(0,0,0,0.4),
      inset 0 1px 7px -1px #ccc,
      inset 0 5px 1px #fafafa,
      inset 0 6px 0 white;
    -moz-box-shadow:0 0 1px rgba(0,0,0,0.4),
      inset 0 1px 7px -1px #ccc,
      inset 0 5px 1px #fafafa,
      inset 0 6px 0 white;
    box-shadow:0 0 1px rgba(0,0,0,0.4),
      inset 0 1px 7px -1px #ccc,
      inset 0 5px 1px #fafafa,
      inset 0 6px 0 white;
  }
  
  .toggle input:checked:hover + label {
    -webkit-box-shadow:0 1px 3px rgba(0,0,0,0.4),
      inset 0 1px 7px -1px #ccc,
      inset 0 5px 1px #fafafa,
      inset 0 6px 0 white;
    -moz-box-shadow:0 1px 3px rgba(0,0,0,0.4),
      inset 0 1px 7px -1px #ccc,
      inset 0 5px 1px #fafafa,
      inset 0 6px 0 white;
    box-shadow:0 1px 3px rgba(0,0,0,0.4),
      inset 0 1px 7px -1px #ccc,
      inset 0 5px 1px #fafafa,
      inset 0 6px 0 white;
  }
  
  .toggle input:checked + label:before {
    z-index:1;
    top:11px;
  }
  
  .toggle input:checked + label:after {
    bottom:9px;
    color:#aaa;
    text-shadow:none;
    z-index:4;
  } */



/* ///////////// */



.cutom-hr{
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: .6rem;
    margin-bottom: .7rem;
}


.group-box{
    display: inline-block;
    border: 1px solid #ced4da;
    background-color: #fff;
    padding: 0.5rem 0.7rem;
    border-radius: .25rem;
    margin: 0 .3rem .5rem 0;
}

.group-box .g-box{
    display: inline-block;
    /* float: right; */
    font-size: .9rem;

}

.group-box .g-control  , .group-box .g-control a{
    margin-left: 5px;
}

.group-box .g-control span:first-child{
    color: #dadada;
}

.group-box .fa-trash-alt {
    color: rgb(220, 109, 109);
}























/*\\\\\\ start login page\\\\\ */




.card-container.card {
    max-width: 350px;
    padding: 30px;
}

/* .btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
} */

/*
 * Card component
 */
 .card-container.card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 30px;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.06);
}

.lock-icon {
    width: 75px;
    /* height: 79px; */
    margin: 0 auto 10px;
    display: block;
    /* -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%; */
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    color: #363636;
    margin: 10px 0 0;
    min-height: 2.5em;
}

.small-label{
    font-size: .75rem;
    color: #666
}

input[type="email"]::-webkit-input-placeholder,input[type="password"]::-webkit-input-placeholder {
    color: rgb(199, 199, 199);
  }

.form-signin #inputEmail,
.form-signin #inputPassword {
    font-size: 14px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: rgb(89, 17, 81);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(91, 18, 82, 0.4);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(91, 18, 82, 0.4);
}

.validation_message{
    font-size: .75rem;
    color: #e04c4c;
    position: relative;
    top: -6px;
    margin-top: 10px !important;
}

.btn.btn-signin {
    /*background-color: #4d90fe; */
    background-color: #831c77;
    /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: #611358;
}

.btn.btn-signin:not(:disabled):not(.disabled).active,
.btn.btn-signin:not(:disabled):not(.disabled):active,
.show>.btn.btn-signin.dropdown-toggle{
    background-color: #611358;
    border-color: #611358;
}

.forgot-password {
    color: #611358;
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: #611358;
}

.new-checkbox{
    position: relative;
    left: calc(1.5rem + 0px);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before, .custm-table .custom-checkbox .custom-control-input:checked~.custom-control-label::before, .custm-table .custom-control-input:checked~.custom-control-label::before {
    background-color: #6f1a65;
}




/*\\\\\\ end login page\\\\\ */

/*\\\\\\ start index page\\\\\ */

.container-body{
    background: white;
    border-radius: 10px;
}

/* start nav logo */

        .nav-logo{
            height: 70px;
            background: #490942;
            border-top-left-radius: 10px;
            padding: 15px;
            display: flex;
            margin-right: -15px;
        }

        .nav-logo .main-logo{
            width: 62px;
        }

        .nav-logo div{
            display: flex;
            align-items: center;
        }

        .nav-title{
            color: white;
            padding-left: 10px;
            letter-spacing: 1px;
        }

/* end nav logo */

/* start nav */

        .main-nav{
            background: #6f1a65;
            border-bottom-left-radius: 10px;
            /* padding: 15px; */
            display: flex;
            margin-right: -15px;
            height: calc(100% - 70px);
        }

        .main-nav ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }
        
        .main-nav ul li a {
            display: block;
            color: #e9cce6;
            padding: 8px 16px;
            text-decoration: none;
            border-bottom: 1px solid #64165b;
            -o-transition: all 0.218s;
            -moz-transition: all 0.218s;
            -webkit-transition: all 0.218s;
            transition: all 0.218s;
        }

        .main-nav ul li:last-child a {
            border-bottom: none;
        }
        
        .main-nav ul li a.active {
            background-color: #64165b;
            color: white;
            box-shadow: inset 4px 0 0 0  #f156a3;
        }
        
        .main-nav ul li a:hover:not(.active) {
            background-color: #64165b;
            box-shadow: inset 4px 0 0 0  #f156a3;
            color: white;
        }
/* end nav */

/* start status bar */

        .nav-items{
            height: 70px;
            background: #fcfcfc;
            border-top-right-radius: 10px;
            /* padding: 15px; */
            display: flex;
            margin-left: -15px;
            border-bottom: 1px solid #ecf0f5; 
            justify-content: flex-end;
        }

        .search-div {
            display: flex;
            align-items: center;
        }

        .search-form .form-group {
            transition: all 0.35s, border-radius 0s;
            /* width: 170px; */
            background-color: #fff;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
            border-radius: 5px;
            border: 1px solid #ccc;
            position: relative;
            margin-bottom: 0;
        }

        .search-form .form-group input.form-control {
            padding-right: 37px;
            border: none;
            background: transparent;
            box-shadow: none;
            display:block;
        }

        .search-form .form-group i.fa-search {
            position: absolute;
            top: 2px;
            right: 4px;
            z-index: 2;
            display: block;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: #9c9c9c;
            left: initial;
            font-size: 14px;
        }
        
        .main-icons{
            display: flex;
            align-items: center;
        }

        .main-icons a{
            margin: 0 7px;
        }

        .main-icons a:first-child{
            margin-left: 15px;
        }

        .main-icons a:last-child{
            margin-right: 15px;
        }

        .main-icons i{
            -o-transition: all 0.218s;
            -moz-transition: all 0.218s;
            -webkit-transition: all 0.218s;
            transition: all 0.218s;
        }

        .main-icons a:hover{
            text-decoration:none;
        }

        .main-icons a:hover i{
            color: #6f1a65;
        }

        .user-login{
            width: 150px;
            display: flex;
            align-items: center;
            border-left: 1px solid #efefef;
            padding-left: 14px;
            height: 70px;
            padding-right: 15px;
        }

        .user-login img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .admin-name{
            line-height: 14px;
            padding-left: 8px;
            display: flex;
        }

/* end status bar */



/* start main content */

        .main-content{
            padding:15px 15px 0 0;
            height: calc(100% - 70px); 
        }

        /* .main-content .row{
            height: 100%;
        } */

        /* start right activity */

            .display-item{
                display: none;
            }
            
            .right-activity{
                margin-top: -15px;
                padding-right: 0px;
                padding-left: 0px;
            }

            .bg-activity{
                background-color: #f8f8f8;
                height: 100%;
                border-bottom-right-radius: 10px;
                padding: 25px 15px;
            }

            .view-user-act{
                display: flex;
                margin-bottom: 10px;
            }

            .view-user-act img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
            .user-log{
                width: 100%;
                padding-left: 10px;
            }
            
            .user-log p{
                margin-bottom: 0;
            }

            .view-btn{
                color: #c5d1e6;
                background: #eaeaea;
                border-radius: 20px;
            }

            .view-btn:hover{
                color:#4a5e80;
            }

        /* end right activity */

/* end main content */



/*\\\\\\ end index page\\\\\ */







/*\\\\\\ start add story page\\\\\ */


/* start status bar */

.statusbar .status-link a{
     color:#495057;
}

.statusbar .status-link span{
    /* font-family: Tahoma; */
    color: #bdbdbd;
}

.statusbar > span:last-child{
    color:#bdbdbd;
}

/* end status bar */


/* start progress bar */
 
  
  /* PROGRESS TRACKER */
  .progress-tracker {
    display: block;
    margin: 0;
    padding: 0;
    counter-reset: item;
    list-style-type: none;
    overflow: auto;
    position: relative;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .075em;
    padding-top: 1rem;
    /* margin: 0 auto; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }

  .progress-tracker .step:before {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 40px;
    color: #ddd;
    display: flex;
    justify-content: center;
    l: "";
    content: "";
    align-items: center;
    /* display: list-item; */
    font-size: 18px;
    line-height: 1.8em;
    /* padding: 0 0 0 13px; */
    position: absolute;
    width: 50px;
    text-align: center;
    height: 50px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    content: counter(item) " ";
    counter-increment: item;
  }
  .progress-tracker .step {
    float: left;
    list-style-position: inside;
    width: 20%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .progress-tracker .step .step-name{
    border-top: 1px solid #ededed;
    display: block;
    margin: 25px 0;
    padding: 35px 0;
    width: 100%;
    color:#ddd;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .progress-tracker .active .step-name{
      color:#6f1a65;
  }

  /*last step*/
  /* .progress-tracker .step:last-child { width: auto;} */
  
  /*active step*/
  .progress-tracker .step.active:before {
      color: #fff;
      background-color: #6f1a65;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.51);
      border: 0;
    }


    
  .progress-tracker .step.s2-active:before {
    box-shadow: none;
  }


/* end progress bar */







/* start add-items */
/* ----------------------------------- Start Step 1  ------------------------------------- */

.upload-hint{
    width: 140px;
    text-align: center;
    margin: 0 auto;
    color: #b8b8b8;
}

.add-items{
    /* text-align: center; */
    display: inline-block;
    justify-content: center;
}

.icon-upload-itm , .icon-upload-itm .new-items{
    width: 100%;
}

#pic{
    display: none;
}
   
.vid-name{
    line-height: 33px;
}

.new-items{
    cursor: pointer;
    width: 224px;
    height: 180px;
    background: #fbfbfb;
    text-align: center;
    border: 1px dashed #e7e7e7;
    padding: 0.5rem;
    position: relative;
}

.new-items .upload-icon{
    width: 70px;
    height: 55px;
}


.new-items .add-nw{
    width: 40px;
    height: 40px;
}


.add-box{
    /* margin:0.5rem; */
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.add-box video{
    height: 100%;
    width: 100%;
}

label.new-items .p-vid p {
    position: relative;
    font-family: "DroidSansArabid", Arial, Helvetica, sans-serif !important;
}



label.new-items .p-vid:hover:after {
    font-family: "Font Awesome 5 Free";
    content: "\f2ed";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.46);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease; */
}
/* ----------------------------------- end Step 1  ------------------------------------- */


/* ----------------------------------- Start Step 2  ------------------------------------- */

/* -----start questions----- */
.add-qus {
    border:1px solid #eee;
    background-color: rgb(249, 249, 249);
    width: 100%;
  }
  
.add-qus .qus-title{
      padding: 15px 1rem;
      border-bottom: 1px solid #eee;
  }

.add-Question{
    padding: 15px
}

.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: .25rem;
}


.circle-ansr{
    width: calc(2.25rem + 2px);
    height: calc(2.25rem + 2px);
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    color:#ccc;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}

.circle-ansr:hover{
    background: #39b54a;
    border-color: #39b54a;
    color:#fff;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    text-decoration: none;
}


/* -----end questions----- */


/* ------start answers------ */

.qus-added {
    border: 1px solid #eee;
    background-color: #fff;
    width: 100%;
}

.qus-added .qus-title{
    padding: 15px 1rem;
}

.qus-added .qus-title p{
    padding: 1rem;
    text-align: center;
    margin-bottom: 0;
    color: #ccc;
    font-weight: 100;
}

.answers-box{
    border: 1px solid #eee;
    background-color: #fff;
    padding: 0.5rem 0.7rem;
}


.text-ansr{
    display: inline-block;
    float: right;
    font-family: "DroidSansArabi", Arial, Helvetica, sans-serif;
    font-size: .9rem;
}

.ansr-control a{
    margin:0 5px; 
}

.ansr-control a:first-child{
    margin-left:0; 
}

.answers-box{
    margin-bottom: .6rem;
}

.answers-box:last-child{
    margin-bottom: 0;
}

.answers-box .fa-trash-alt{
    color:rgb(220, 109, 109);
}

.answers-box .fa-edit{
    color:#2569cf;
}

.ansr-control > span{
    color: #e4e4e4;
}

/* ------end answers------ *
/

/* ----------------------------------- end Step 2  ------------------------------------- */

/* ----------------------------------- start Step 3  ------------------------------------- */

/* start evaluation-accordion */

    .evaluation-accordion .checkout-step-title a {
        color: rgb(53, 53, 53);
    }
    .evaluation-accordion .checkout{
        background-color: #fff;
        /* border:1px solid #eaefe9; */
        font-size: 14px;
    }
    .evaluation-accordion .panel{margin-bottom: 0px;}
    .evaluation-accordion .checkout-step {
        
        border-top: 1px solid #f2f2f2;
        color: #666;
        font-size: 14px;
        padding: 30px;
        padding-bottom: 0;
        position: relative;
    }

    .evaluation-accordion .checkout-step:nth-child(1) {
        border: 0;
    }

    .evaluation-accordion .checkout-step-number {
        border-radius: 50%;
        border: 5px solid #ebebeb;
        background-color: #242424;
        display: inline-block;
        font-size: 12px;
        height: 56px;
        margin-right: 6px;
        padding: 6px;
        text-align: center;
        width: 56px;
        line-height: 27px;
        color: #fff;
        font-size: 1.5rem;
        position: relative;
        z-index: 2;
    }

    .evaluation-accordion .step-number {
        position: relative;
        padding-bottom: 30px;
    }

 
    .evaluation-accordion .step-number:after {
        content: " ";
        position: absolute;
        left: 28px;
        z-index: 1;
        width: 1px;
        height: 87px;
        overflow: hidden;
        background-color: #f2f2f2;
    }

    .evaluation-accordion .panel:not(:first-child) .step-number:after {
        content: " ";
        position: absolute;
        top: -30px;
        left: 28px;
        z-index: 1;
        width: 1px;
        height: 120px;
        overflow: hidden;
        background-color: #f2f2f2;
    }
        
    .evaluation-accordion .checkout-step-title{ font-size: 18px;
        font-weight: 500;
        vertical-align: middle;display: inline-block; margin: 0px;
    }

    .evaluation-accordion .step-content{
        position: relative;
        width: calc(100% - 28px);
        max-width: 85%;
        margin-left: 28px;
        padding: 0 37px;
        padding-bottom: 30px;
        /* height: calc(100% + 30px); */
        border-left: 1px solid #f2f2f2;
        /* background: red; */
    }

/* end evaluation-accordion */


/* -----start modal content---- */
.custom-serch{
    position: relative;
}

.custom-serch i{
    position: absolute;
    left: 14px;
    top: 13px;
    color: #495057;
}

.custom-serch .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 35px;
}


.modal-body .navigation{
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.article-box{
    border: 1px solid #e9ecef;
    padding: 1rem;
}

.c-checkbox{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-checkbox .custom-control-label::after {
    top: -0.9rem;
    left: -.9rem;
}

.c-checkbox .custom-control-label::before {
    top: -0.9rem;
    left: -.9rem;
}


img.modal-img{
    vertical-align: middle;
    border-style: none;
    width: 100%;
}

.post-icons{
    border-top: 1px solid #e9ecef;
}

.post-icons a.viewNum{
    color:rgb(53, 53, 53);
}

.post-icons a.like{
    color:#b11b9f;
}


.post-icons a.comment{
    color:#d01721;
}



.post-icons a.share{
    color:#1788d0;
}


.post-icons a:hover{
    text-decoration: none;
}


/* -----end modal content---- */

/* ----------------------------------- start Step 3  ------------------------------------- */

/* ----------------------------------- start Step 4  ------------------------------------- */



.add-items-s3{
    text-align: center;
    /* display: inline-block; */
    /* justify-content: center; */
}

.chara-box{
    border: 1px solid #e7e7e7;
    padding: .5rem;
}

.cover-character{
    /* max-width: 244px; */
    width: auto;
    /* background: url(../images/cover-character.jpg) no-repeat center; */
    height: 150px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-character:after{
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.48);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.cover-character img{
    position: relative;
    z-index: 1;
    width: 130px;
    height: 130px;
}


.dir-trl{
    direction: rtl;
}

.view-answer{
    /* text-align: right; */
}


.ture-circle , .false-circle{
    font-size: 1.2rem !important;
    margin-right: .5rem;
}

.ture-circle{
    color: #39b54a;
}


.false-circle{
    color: #999999;
}

span.ansr{
    line-height: 9px;
    vertical-align: text-top;
}


/* -------start answer with pictures-------- */

div.ansr{
    display: inline-block;
    position: relative;
}

div.ansr img {
    display: inline-block;
    width: 88px;
    height: 60px;
    border: 3px solid #545454;
    margin-top: .5rem;
}

div.ansr i{
    position: absolute;
    bottom: 0px;
    right: -8px;
    background: #545454;
    padding: 5px;
}


/* ---------end answer with pictures-------- */

/* ----------------------------------- end Step 4  ------------------------------------- */




/* end add-items */


/*\\\\\\ end add story page\\\\\ */





/* \\\\\\ start user management page  \\\\\\ */

.alluserMng label {
    display: inline-block;
    margin-bottom: .1rem;
}

.control-filter-btn button{
    border: 1px solid #ddd;
    background: rgb(232, 232, 232);
    height: auto !important;
    font-size: 0.80rem;
    display: block;
    border-radius: 6px;
    margin: 3px;
    cursor: pointer;
}

.control-filter-btn button:hover{
    background-color: rgb(189, 189, 189);
    border: 1px solid rgb(189, 189, 189);

}

.control-filter-btn button.active{
    background-color: rgb(111, 26, 101);
    border: 1px solid rgb(111, 26, 101);
    color: white;
}

.control-filter-btn button i:first-child{
    font-size: 0.67rem;
}

/* \\\\\\ end user management page  \\\\\\ */


/* \\\\\\ start complaints and suggestions page  \\\\\\ */

.card.cstm-card{
    border: none;
}

.filter-box{
    padding: 1rem!important;
    background: #f1f7f9;
}

.filter-box .form-group{
    margin-bottom: .5rem;
}


.tabs {
    /* max-width: 640px; */
    /* margin: 0 auto; */
    /* padding: 0 20px; */
  }
  #tab-button {
    display: table;
    table-layout: fixed;
    /* width: 100%; */
    margin: 0;
    padding: 0;
    margin-left: -40px;
    list-style: none;
  }
  #tab-button li {
    display: table-cell;
    /* width: 20%; */
  }
  #tab-button li a {
    display: block;
    padding: .5em;
    background: #eee;
    border: 1px solid #ddd;
    text-align: center;
    color: #000;
    text-decoration: none;
    padding: 1rem 2rem;
    border-bottom: none;
    position: relative;
    bottom: -1px;
  }
  #tab-button li:not(:first-child) a {
    border-left: none;
  }
  #tab-button li a:hover,
  #tab-button .is-active a {
    border-bottom-color: transparent;
    background: #fff;
  }
  .tab-contents {
    padding: 1rem;
    border: 1px solid #ddd;
  }
  
  .sug-notification{
    display: inline-block;
    padding: 2px 6px;
    color: white;
    line-height: 16px;
    font-size: .8rem;
    background: #6f1a65;
    border-radius: 5px;
    margin-left: 4px;
  }
  
  .tab-button-outer {
    display: none;
  }
  .tab-contents {
    margin-top: 20px;
  }

.tab-button-outer {
    position: relative;
    z-index: 2;
    display: block;
}
.tab-select-outer {
    display: none;
}
.tab-contents {
    position: relative;
    top: -1px;
    margin-top: 0;
}

.chat-box{
    border-radius: 0;
    background: #fbfbfb;
}

.chat-box:not(:first-child) {
    margin-bottom: 1rem;
}

.chat-box a{
    color: #353535
}

.cop-content{
    max-height: 60px;
    overflow: hidden;
}

.cop-content a{
 text-decoration: none;
 text-align: justify;
}


.C-user-details{
    width: 1rem;
    display: inline-block;
}


.e-user-details{
    padding-left: 1rem;
    display: inline-block;
}

.message-status{
    display: inline-block;
    margin-bottom: .3rem;
}

.message-status label{
    margin-bottom: .2rem;
}
.message-status .form-control{
    font-size: inherit;
    height: unset;
    padding: .275rem .75rem;
    /* line-height: .5rem; */
    /* margin-left: 1rem; */
}



/* -----start chat----- */


.inbox_people {
	background: #fff;
	float: left;
	overflow: hidden;
	width: 30%;
	border-right: 1px solid #ddd;
}

.inbox_msg {
	border: 1px solid #ddd;
	clear: both;
	overflow: hidden;
}

.top_spac {
	margin: 20px 0 0;
}

.recent_heading {
	float: left;
	width: 40%;
}

.srch_bar {
	display: inline-block;
	text-align: right;
	width: 60%;
}

.headind_srch {
	padding: 10px 29px 10px 20px;
	overflow: hidden;
	border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
	color: #0465ac;
    font-size: 16px;
    margin: auto;
    line-height: 29px;
}

.srch_bar input {
	outline: none;
	border: 1px solid #cdcdcd;
	border-width: 0 0 1px 0;
	width: 80%;
	padding: 2px 0 4px 6px;
	background: none;
}

.srch_bar .input-group-addon button {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	padding: 0;
	color: #707070;
	font-size: 18px;
}

.srch_bar .input-group-addon {
	margin: 0 0 0 -27px;
}

.chat_ib h5 {
	font-size: 15px;
	color: #464646;
	margin: 0 0 8px 0;
}

.chat_ib h5 span {
	font-size: 13px;
	float: right;
}

.chat_ib p {
    font-size: 12px;
    color: #989898;
    margin: auto;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat_img {
	float: left;
	width: 11%;
}

.chat_img img {
	width: 100%
}

.chat_ib {
	float: left;
	padding: 0 0 0 15px;
	width: 88%;
}

.chat_people {
	overflow: hidden;
	clear: both;
}

.chat_list {
	border-bottom: 1px solid #ddd;
	margin: 0;
	padding: 18px 16px 10px;
}

.inbox_chat {
	height: 550px;
	overflow-y: scroll;
}

.active_chat {
	background: #e8f6ff;
}

.incoming_msg_img {
	display: inline-block;
	width: 6%;
}

.incoming_msg_img img {
    border-radius: 50%;
    width: 38px;
    height: 38px;
}

.received_msg {
	display: inline-block;
	padding: 0 0 0 10px;
	vertical-align: top;
	width: 92%;
}

.received_withd_msg p {
	background: #ebebeb none repeat scroll 0 0;
	border-radius: 0 15px 15px 15px;
	color: #646464;
	font-size: 14px;
	margin: 0;
	padding: 5px 10px 5px 12px;
	width: 100%;
}

.time_date {
	color: #747474;
	display: block;
	font-size: 12px;
	margin: 8px 0 0;
}

.received_withd_msg {
	width: 65%;
}

.mesgs{
	/* float: left;
	padding: 30px 15px 0 25px;
	width:70%; */
}

.sent_msg p {
	background:#6f1a65;
	border-radius: 12px 15px 15px 0;
	font-size: 14px;
	margin: 0;
	color: #fff;
	padding: 5px 10px 5px 12px;
	width: 100%;
}

.outgoing_msg {
	overflow: hidden;
	margin: 26px 0 26px;
}

.sent_msg {
	float: right;
	width: 60%;
}

.input_msg_write input {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	color: #4c4c4c;
	font-size: 15px;
	min-height: 48px;
	width: 100%;
    outline:none;
    padding-right: 45px;
}

.type_msg {
	border-top: 1px solid #eaeaea;
	position: relative;
}

.msg_send_btn {
	background: #6f1a65 none repeat scroll 0 0;
	border:none;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
	font-size: 15px;
	height: 33px;
	position: absolute;
	right: 0;
	top: 11px;
	width: 33px;
}

.messaging {
	padding: 0 0 50px 0;
}

.msg_history {
	height: 516px;
	overflow-y: auto;
}

/* -----end chat----- */


/* \\\\\\ end complaints and suggestions page  \\\\\\ */


/* \\\\\\ Start FAQ page  \\\\\\ */

.cstom-accordion .card-header {
    padding: .75rem 1rem;
}

.acor-btn-group{
    display: inline-block;
    float: right;
}

.cstom-accordion .card-header button.btn.btn-link {
    padding: 0;
    color: inherit;
    text-decoration: none;
}

.cstom-accordion .card-header button.btn.btn-link i{
    margin-right: .33rem;
}


.acor-btn-group button{
    border: 1px solid #ddd;
    background: #f7f7f7;
    cursor: pointer;
    border-radius: .3rem;
    font-size: .9rem;
    padding: .4rem 1rem
}

.acor-btn-group button:hover{
    background: #ddd;
}


.cstom-accordion .card-header button.btn-link:not(.collapsed) i:before {
    content: "-";
    font-size: 30px;
    line-height: 10px;
}
.cstom-accordion .card-header button.btn-link i {
    color: #999;
    font-size: 12px;
}



/* \\\\\\ End FAQ page  \\\\\\ */



/* \\\\\\ start Customer page  \\\\\\ */

/* start children-box */

.children-box{
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    padding: 1rem;
}

.first-row{
    position: relative;
    border-bottom: 1px solid#ddd;
    /* padding-left: 77px */

}

.first-row > div , .second-row > div {
    width: calc(100% - 120px);
    float: right;
}

.first-row > div{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
}

.first-row , .second-row{
    min-height: 60px;
}

.second-row{
    padding-top: 1rem;
}

.Chaild-pic{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    bottom: -50px;
}


.chaild-title{
    display: inline-block;
}


.first-row input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.first-row label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 30px;
    background: #eaeaea;
    border: 1px solid #d4d4d4;
	display: inline-block;
	border-radius: 100px;
	position: relative;
}

.first-row label:after {
	content: '';
	position: absolute;
    top: 3px;
    left: 4px;
    width: 22px;
    height: 22px;
	background: #581260;
	border-radius: 90px;
	transition: 0.3s;
}


.first-row label{
    margin: 0;
}

.first-row input:checked + label {
    background: #fff;
    border: 1px solid #d4d4d4;
}

.first-row input:checked + label:after {
	left: calc(100% - 4px);
	transform: translateX(-100%);
}

.first-row label:active:after {
	width: 23px;
}

.chaild-btns button{
    width: calc(50% - 2px);
    color: inherit;
    border: 1px solid #ddd;
}
.chaild-btns .btn-outline-secondary:hover{
    border: 1px solid #ddd !important;
    background: #f7f7f7 !important;
    color: inherit !important;
}

.chaild-btns .btn-outline-secondary:not(:disabled):not(.disabled):active{
    border: 1px solid #ddd !important;
    background: #f7f7f7 !important;
    color: inherit !important;
}

.chaild-btns .btn-outline-secondary:focus , .chaild-btns .btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

/* end children-box */

/* \\\\\\ end Customer page  \\\\\\ */



/* \\\\\\ start Reporting pages \\\\\\ */

.custom-grid{
    margin: 15px;
    margin-top: 0px;
}


.head-row{
    line-height: 45px;
}

.title-main-style{
    background: #80497a;
    padding: 1rem;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.t-head .t-title{
    min-height: 80px;
}


.t-row{
    background: #fafafa;
}

.t-row:nth-child(even){
    background: #f5f5f5;
}

.head-row hr{
    margin: 0;
}

.rest-t-head-style{
    justify-content: unset;
}

.small-label.visibility{
    visibility: hidden;
}


.rest-t-head-style .small-label{
    color: #fff;
}


.title-main-style .custom-control {
    text-align: left;
    margin-bottom: .4rem;
}

.custome-chart button.btn.btn-outline-secondary:hover , .custome-chart button.btn.btn-outline-secondary:active , .custome-chart .btn-outline-secondary:not(:disabled):not(.disabled).active{
    background: #80497a;
    color:white;
    border-color: #80497a;
}


.details-table .search-form .form-control{
    border-color: 1px solid #ced4da;
    height: unset;
}
.details-table .search-form .form-group{
    box-shadow: none;
    border: 1px solid #ced4da;
}


.details-table .navigation {
    display: flex !important;
    justify-content: center !important;
    align-items: baseline !important;
}


.details-table .table thead th {
    vertical-align: middle;
}


.details-table .table .search-form .form-group i.fa-search {
    position: absolute;
    top: -3px;
    right: auto;
    left: -8px;
    font-size: .7rem;
    color: #a2859f;
}

.details-table .table .details-table .search-form .form-group {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
}

.details-table .table  .search-form .form-group {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #a2859f;
    background: transparent;
}

.details-table .table .search-form .form-group input.form-control,
.details-table .table select.form-control {
    color: #d6b6d3;
    font-size: .9rem;
    padding: .275rem .75rem;
    padding-left: 18px;
    line-height: 1;
}

.details-table .table .search-form .form-group input.form-control::placeholder , .details-table .table .duration-s .form-control::placeholder{
    color:#a2859f;
}

.details-table .table select.form-control , .details-table .table .duration-s .form-control {
    border: none;
    border-bottom: 1px solid #a2859f;
    background: transparent;
    box-shadow: none;
    display: block;
    border-radius: 0;
    height: auto;
    padding: .275rem 0rem;
    color: #d6b6d3;
    font-size: .9rem;
}





/* \\\\\\ end Reporting pages \\\\\\ */














/*\\\\\\ start footer \\\\\ */

footer p{
    font-size: .8rem;
    color: white;
}

footer.footer-style p{
    color: #4a5e80;
}


/*\\\\\\ end footer \\\\\ */