.gamePreview img
{
    margin-left: auto;
    margin-right: auto;
    padding: 2px;
}

.gamePreview {
    margin-bottom: 8px;
}

.gamePreview span
{
    position: absolute;
    right: 25px;
    top: 10px;
    cursor: pointer;
    background-color: #fff;
    padding: 2px 5px;
    line-height: 10px;
    border-radius: 5px;
    font-size: 13px;
}

.gamePreview .add-box
{
    height: 115px;
}

.gamePreview .new-items
{
    height: 150px;
}


.gamePreview.dragDrop a span
{
    margin-right: 26px;
}




.allGames .card
{
    padding: 8px 25px 8px;
}


.allGames .card .borderRight
{
    border-right: 1px solid #eee;
}

.element
{
    border: 1px solid #eee;
    padding: 15px 20px;
}

.editBTNs button
{
    padding: 8px 20px;
    margin-left: 10px;
}

.editBTNs .colorBTN,
.editBTNs .colorBTN:active
{
    background: #6f1a65 !important;
    border: 1px solid #6f1a65 !important;
    color: white;
}

.btn-dark{
    background-color: black !important;
    color: white !important;
}
.editBTNs .colorBTN:hover
{
    background: #6f1a65e0 !important;
}
