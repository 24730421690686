.circles-labels {
    margin-top: -10px
}

.circle1 {
    display: inline-block;
    height: 50px;
    width: 50px;
    background-color: #7d276d;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: small;
    /* cursor: pointer; */
    /* box-shadow: 0px 0px 27px 0px rgba(58, 58, 58, 0.1); */
    /* border: 17px; */
    border: 2px solid #fff;
    box-shadow: 0px 0px 6px 0px rgba(2, 2, 2, 0.4);
    line-height: 49px;
    text-align: center;
    border-color: rgb(213, 213, 213);
}

.noShadow {
    box-shadow: 0px 0px 27px 0px rgba(58, 58, 58, 0.1);
}

#space {
    margin-left: 40px;
}

.circle-label {
    background-color: white;
    display: inline;
    text-align: center;
    color: purple;
    margin-left: 20px;
    /* box-shadow: 0px 0px 27px 0px rgba(58, 58, 58, 0.1); */
    /* border: 17px; */
    /*box-shadow: 0px 0px 6px 0px rgba(2, 
    2, 2, 0.4);*/
    text-align: center;
    border-color: rgb(213, 213, 213);
}

iframe{
    width: 100%;
}