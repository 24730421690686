.editor{
    height: 200px;
    border-radius: 50%
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    height: 100px;
    border-color: gray;
    border-style: solid;
    border-width: 0.5px;

}