
.seriesButtons 
{
    background-color: white;
    color: purple;
    border: 1.5px solid Purple;
    border-radius: 7px;
    font-size: 14px;
    padding: 5px 10px;
    float: right;
    margin-right:14px;
    margin-top: -20px
}

.featured-checkbox{
    font-size: 14px;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
}


#modal{
    width: 43%;
    height:  auto;
    margin: 3% 0% 0% 32%;
    padding-bottom: 10px;  
}
