.panel .row:nth-child(even){
 background-color: #f2f2f7;
}
.panel .row:nth-child(odd){
 background-color: #f3f8f8;
}

.serchcntent {
      background: #fff;
  }
.serchcntent:nth-child(odd) {
    background: #ccc;
}
.pagination {
  display: flex;
  border: 1px solid #fff;
  /*justify-content: space-between;*/
  padding: 20px;
}
.pagination button {
  padding: 10px;
}
.page-active {
  background: black;
  color: #fff;
  border: 1px solid #fff;
}

assign-btn{
  float: right;
  background: purple;
  color: white;
}      


