body {
    font-family: 'Arial', sans-serif;
    background-color: #f2f2f7;
    margin: 0;
    padding: 20px;
  }
  
  .dashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .filters {
    display: flex;
    gap: 10px;
  }
  
  .filters select,
  .filters input {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: white;
    font-size: 14px;
  }
  
  .usage-section {
    background-color: #e6e0e9;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e6e0e9;
    border-radius: 10px;
    padding: 3px;
  }
  
  .header-bar p {
    font-size: 20px;
    text-align: left;
    color: #333;
    font-weight: bold;
  }
  
  .total-hours {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    background-color: #ffffff;
    padding: 6px 12px;
    border-radius: 20px;
  }
  
  .store-metrics {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .store-metrics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .store-metrics-header h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  .summary-frequency {
    background-color: #e6e0e9;
    padding: 6px 12px;
    border-radius: 5px;
  }
  
  .summary-frequency p {
    color: #666;
    font-size: 14px;
    margin: 0;
  }

  .metrics-description {
    font-size: 14px;
    color: #888;
    margin: 10px 0;
  }
  
  .metrics-summary {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 15px;
    margin-bottom: 20px;
    border-top: 1px solid #e6e0e9;
    padding-top: 15px;
  }
  
  .period-summary {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .vertical-line {
    width: 2px;
    height: 40px;
    background-color: #e6e0e9;
    margin-right: 10px;
  }
  
  .period-content {
    display: flex;
    flex-direction: column;
  }
  
  .period-label {
    font-size: 12px;
    color: #7d7d7d;
    margin: 0;
  }
  
  .percentage-and-hours {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }
  
  .period-percentage {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .period-hours {
    font-size: 14px;
    color: #277856;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .period-hours .arrow {
    color: #277856;
    margin-right: 4px;
    font-size: 12px;
  }

  .chart-and-legend {
    display: flex;
    align-items: center;
  }
  
  .legend-container-right {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    gap: 10px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .views-activities {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .views,
  .activities {
    background-color: #e6e0e9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex: 1;
    position: relative;
  }
  
  .views h3,
  .activities h3 {
    font-size: 18px;
    margin: 0;
    display: inline-block;
  }

  .views-header,
.activities-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
  
  .views p,
  .activities p {
    background-color: #e6e0e9;
    color: #333;
    font-size: 12px;
    display: inline-block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: #ffffff;
    margin-left: 10px;
    font-weight: bold;
    color: #881464;
  }

  .views h3,
  .activities h3 {
    background-color: #e6e0e9;
    color: #333;
    font-size: 12px;
    display: inline-block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: #ffffff;
    margin-left: 10px;
    font-weight: bold;
    color: #000000;
  }
  
  .view-item,
  .activity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .view-item img,
  .activity-item img {
    width: 78px;
    height: 64px;
    margin-right: 15px;
  }
  
  .view-item span,
  .activity-item span {
    background-color: #881464;
    padding: 8px 16px;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .view-item div,
  .activity-item div {
    display: flex;
    align-items: center;
  }
  
  .view-item p,
  .activity-item p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  .disabled {
    opacity: 0.5; /* Dimmed out */
    cursor: not-allowed; /* Show non-clickable cursor */
}

.disabled:hover {
    transform: none; /* Disable hover effect */
}
