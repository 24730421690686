
.statusbar .status-link a{
    color:#495057;
}

.statusbar .status-link span{
   /* font-family: Tahoma; */
   color: #bdbdbd;
}

.statusbar > span:last-child{
   color:#bdbdbd;
}

/* end status bar */

.small-font{
   font-size: .8rem ;
}

.x-small-font{
   font-size: .7rem;
}

.main-title-container > .main-title{
   display: inline-block;
   line-height: 24px;
}

#btn-add{
   color: #fff;
    background-color: purple;
    border-color: purple;
}
