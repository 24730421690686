.contentNav .dropdown-menu,
.contentNav .dropdown-item:focus,
.contentNav .dropdown-item:hover
{
    background-color: #6f1a65;
}
.contentNav .dropdown-menu
{
    padding: 0px;
    top: -2px !important;
    border-radius: 0px;
    width: 100%;
    box-shadow: 1px 1px 5px 1px #6d1563;
    border: 1px solid #962088;
}
.contentNav .main-nav ul li a
{
    color: #fff !important;
}

.dropdown:hover>.dropdown-menu
{
   display: block !important;
   position: unset;
} 