/* Modal Styles */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* Input Field */
.modal-input,
.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Toggle Container for Group Status */
.toggle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.modal-label {
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-top: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6F1966;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.toggle-label {
  margin-left: 5px;
  font-size: 14px;
}

/* Student List */
.student-list {
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
}

.student-item {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  transition: background-color 0.3s, border 0.3s;
}

.student-item.selected {
  background-color: #e0f7fa;
  border: 1px solid #6F1966;
}

.student-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.student-info {
  flex-grow: 1;
}

.student-name {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.student-date {
  margin: 0;
  font-size: 12px;
  color: #777;
}

/* Custom Circular Checkbox */
.student-checkbox {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-left: 10px;
  position: relative;
}

.student-item.selected .student-checkbox {
  background-color: #4caf50; /* Green for selected */
}

.checkmark {
  display: none;
  width: 10px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.student-item.selected .checkmark {
  display: block;
}

/* Step Buttons */
.step-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.assign-btn,
.next-btn,
.back-btn {
  background-color: #6F1966;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.assign-btn:hover,
.next-btn:hover,
.back-btn:hover {
  background-color: #5a0cad;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  max-width: 500px;
  width: 100%;
}
/* Error Message Styles */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.modal-text {
  font-size: 1em;
  color: #333;
  margin-bottom: 20px;
}

.group-name {
  font-weight: bold;
  color: red;
}

.delete-btn {
  background-color: #6F1966;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
}

.delete-btn:hover {
  background-color: darkred;
}
