.preview{
    border-color: black;
    width: 350px;
    /* border-style: solid; */
    margin-top: 15px;
    padding: 0px
}

.radio-box{
    line-height: 180px;
}

.preview .p
{
    white-space: pre-wrap;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hideBorder .flex{
    border-style: hidden !important;
    box-shadow: none !important;
    box-sizing:border-box !important;
    
}
.grey{
    width: 350px;
    border-radius: 10%;
    background-color: #f8f9fa!important;
    margin: 0;
    border: 0.5px solid rgba(34, 36, 38, .15);
}

.hideProgress .progress-bar-wrapper{
    display: none !important;
}

/* .toggle-play-button 
.toggle-play-wrapper*/
/* .hideProgress .toggle-play-button  {
    width: 20px !important;
    height:  20px !important;
}
.hideProgress .play-icon  {
    width: 5px !important;
    height:  5px !important;
} */
