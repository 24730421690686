.activity-detail-view {

}

.activity-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 50px;
}

.activity-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
}

.activity-image-container {
    flex: 1;
    margin-right: 29px;
}

.main-activity-image {
    width: 450px;
    height: 300px;
    border-radius: 16px;
}

.activity-summary-and-info {
    flex: 2;
}

.activity-summary-container {
    display: flex;
    gap: 10px; /* Reduced gap between boxes */
    justify-content: flex-start;

}

.summary-box {
    background: #f0f4f7;
    border-radius: 12px;
    padding: 15px;
    width: 180px; 
    text-align: center;
    box-shadow: none;
}

.summary-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.summary-box h4 {
    font-size: 14px;
    color: #b5b6c4;
    margin: 0;
}

.summary-value {
    font-size: 16px;
    font-weight: bold;
    color: #821460;
    margin-top: 5px;
}

.evaluation-score {
    color: #45ac5c; 
}

.activity-info {
    margin-top: 30px;
    font-size: 14px;
    color: #666;
    line-height: 1.6;
}

.activity-info h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #999999;
}

.activity-info p {
    font-size: 14px;
    line-height: 1.5;
    color: #555;
    text-align: right; 
    direction: rtl; 
}


/* Educational Activity Section */
.educational-activity {
    margin-top: 20px;
    padding: 10px;

}

.educational-activity h4 {
    font-size: 16px;
    color: #000000;
    margin-bottom: 5px;
}

.educational-activity p {
    font-size: 14px;
    color: #767678;
}

/* Educational Links Section */
.educational-section {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.educational-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    width: 40%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Inherit text color */
    transition: transform 0.2s ease-in-out;
}

.educational-link:hover {
    transform: scale(1.05); /* Subtle hover effect */
}

.educational-link h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
}

.educational-icon {
    width: 60px;
    height: 60px;
    margin-top: 10px;
}