
/* @font-face {
font-family: DroidSansArabi;
src: url("../webfonts/DroidSansArabi.ttf");
font-weight: 100;
}

/* html{
    background: white !important;
} */



/* ///--- start custom scrollbar---/// */

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ededed; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #353535; 
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #353535; 
  }
  
/* ///--- end custom scrollbar---/// */

 .ui-modal{
    -webkit-transform: translate(0,0) !important;
    transform: translate(0,0) !important;
    position: relative !important;
    height: auto;
}


.leftToRight{
    min-height: 100%;
    text-align: right;
    direction: rtl;
    color: #333;
    position: relative;
}

/* .payment{
    min-height: 100%;
    background: white;
    z-index: 1;
    position: relative;
} */


.main-payment {
    background: white;
    /* position: relative;
    top: 0;
    min-height: 100%;
    height: 100%; */
    direction: rtl;
    text-align: right;
}

.font{
    font-family: DroidSansArabi !important;
}

.border{
    border: 1px solid #ddd;
    padding: 1rem;
}
/* *{
    font-family: DroidSansArabi !important;
} */

/* 
.main-payment::before {
    content: "";
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
} */

.main-payment .ltr{
    direction: ltr;
}

/* .card.card-container {
    padding: 0;
} */

.main-payment .bg-img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 625px;
    background-image: url(../../../images/bg.jpg);
    background-repeat: no-repeat;
    background-position: center 0px top 0%;
    background-size: cover;
    border-bottom: 1px solid #ddd;
    height: 50%;
    z-index: 0;
    /* -webkit-clip-path: ellipse(110% 50% at 50% 10%);
    clip-path: ellipse(110% 50% at 50% 5%); */
  }




.main-payment .h7{
    font-size: .8rem;
}

.main-payment .small-font{
    font-size: .9rem;
    font-family: DroidSansArabi !important;

}

.main-payment .x-small-font{
    font-size: .7rem;
    font-family: DroidSansArabi !important;

}


.main-payment .second-color{
    color: #9a9a9a !important;
}

.main-payment .main-logo{
    color: white;
    margin-top: 2rem;
    width: 100%;
    position: relative;
}

.main-payment .card-padding-5{
    padding: 3rem;
}

.main-payment a{
    color: #333;
}


.main-payment .btn{
    font-weight: 100 !important;
}

.main-payment label {
    margin-bottom: .5rem;
}

.main-payment .danger-color{
 color: #fa5a53;
}

.main-payment .en-font{
    font-family: Arial, Helvetica, sans-serif !important;

}

.main-payment a:hover {
    color: inherit;
}

.main-payment .eng-lang{
    font-family: Arial, Helvetica, sans-serif !important;
}

.main-payment .validation_message{
    color: red;
    margin-top: .30rem;
}

.main-payment .Pkg-price p span{
    display: inline-block;
    margin: 0 2px;
}


.mo-package.paymentltr.pay{
    left: 0;
}


/* /////////// start checkbox  ////////// */

                            
                            .main-payment .checkbox {
                                display: block;
                                position: relative;
                                padding-left: 26px;
                                margin-bottom: 12px;
                                cursor: pointer;
                                font-size: 14px;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                            }
                            
                            /* Hide the browser's default checkbox */
                            .main-payment .checkbox input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0;
                                width: 0;
                            }
                            
                            /* Create a custom checkbox */
                            .main-payment .checkmark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 20px;
                                width: 20px;
                                background-color: #ffffff;
                                border-radius: .25rem;
                                border: 1px solid #d1d7dc;
                            }
                            
                            /* On mouse-over, add a grey background color */
                            .main-payment .checkbox:hover input ~ .checkmark {
                                background-color: #ccc;
                            }
                            
                            /* When the checkbox is checked, add a blue background */
                            .main-payment .checkbox input:checked ~ .checkmark {
                                background-color: #353535;
                            }
                            
                            /* Create the checkmark/indicator (hidden when not checked) */
                            .main-payment .checkmark:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                            
                            /* Show the checkmark when checked */
                            .main-payment .checkbox input:checked ~ .checkmark:after {
                                display: block;
                            }
                            
                            /* Style the checkmark/indicator */
                            .main-payment .checkbox .checkmark:after {
                                left: 7px;
                                top: 3px;
                                width: 5px;
                                height: 9px;
                                border: solid white;
                                border-width: 0 2px 2px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
/* /////////// end checkbox  ////////// */








/* ///////////////////// start login //////////////////// */

.main-payment .login{
    width: 350px !important;
}

.main-payment .login .custom-control-label::before {
    top: 0rem;
    right: 0rem;
}


.main-payment .login .custom-control-label::after {
    top: 0rem;
    right: 0rem;
}

.main-payment .login .custom-control-label {
    padding-right: 23px;
}

/* ////////////////////// end login ///////////////////// */





/*\\\\\\\\\\\\\\\\\\\\\ start pay page\\\\\\\\\\\\\\\\\\\\ */




.main-payment .card.card-container{
    margin: 0;
    max-width: none;
    padding: 0;
}


.main-payment .card-header.profile-name-card {
    margin: 0;
}


.main-payment .pay-box .card-header{
    padding: 1.7rem 1.25rem;
}


.main-payment .pay-box{
        position: inherit;
        transform: inherit;
        right: 0;
        top: 50%;
        left: 50%;
    }

.main-payment .pay-box .card-container.card {
    width: 450px;
    padding: 0px;
}

.main-payment .btn {
    font-weight: 700;
    height: 38px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

.main-payment .pay-box .card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 30px;
    /* shadows and rounded borders */
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -moz-box-shadow: 0px 0px 60px 10px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 60px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 60px 10px rgba(0, 0, 0, 0.15);
    border:0;
}

.main-payment .price-value{
    font-weight: bold;
    color: #ffad4d;
    text-align: center;
}

.main-payment .mo-package{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.main-payment .mo-package span{
    color: #808080;
}

.main-payment .mo-duration{
    display: inline-block;
    width: 30px;
    line-height: 22px;
    text-align: center;
    /* height: 30px; */
    background: #333;
    border-radius: 30px;
    padding: 4px;
    color: #fff;
}

.main-payment .mo-duration-1{
    width: 100%;
}


.main-payment .profile-name-card {
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    /* color: #ab4398; */
    /* text-transform: uppercase; */
}

.main-payment .profile-name-card:first-child{
    font-weight: 100;
}

.main-payment .small-label{
    font-size: .75rem;
    color: #666
}



.main-payment .btn.btn-primary {
    background-color: #353535;
    padding: 0px 1rem;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.main-payment .card-body {
    padding: 1.5rem;
}

.main-payment fieldset {
    font-family: sans-serif;
    border: 1px solid #e1e1e1;
    background: transparent;
    padding: 15px;
}

.main-payment fieldset legend {
    /* background: #e1e1e1; */
    color: #363636;
    padding: 5px 10px 0 10px;
    margin-bottom: 0;
    font-size: 14px;
    border-radius: 5px;
    /* margin-left: 20px; */
    width: auto;
}

.main-payment .pay-box .card-header {
    border-radius: 15px 15px 0px 0px;
}

.main-payment .pay-box .div-box{
    padding: 1rem;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 9px 0px rgba(169, 169, 169, 0.49);
}



.main-payment .back-icon i{
    font-size: 1.5rem;
    line-height: 28px;
}


/*\\\\\\\\\\\\\\\\\\\\\\\\\\ end pay page\\\\\\\\\\\\\\\\\\\\\\\\\ */











/*\\\\\\\\\\\\\\\\\\\\\\\\\\ start index page\\\\\\\\\\\\\\\\\\\\\\\\\ */




.main-payment .logo{
    height:70px;
    margin-bottom: 10px; 
}





/* ///--------start main containder --------//// */

.main-payment .main-containder  .card-header{
    padding: 1rem;
}

.main-payment .main-containder .card {
    border-radius: 1rem;
}

.main-payment .Current-Package hr {
    border: 0;
    clear:both;
    display:block;
    /* width: 96%;                */
    background-color:rgb(235, 235, 235);
    height: 1px;
    margin-top: 0rem !important;
  }
  .main-payment .Current-Package .checkmark {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
}

.main-payment .Current-Package .checkbox {
    padding-right: 26px;
}


.main-payment .Current-Package .checkbox input:checked ~ .checkmark {
    background-color: #ffae4d;
    border-color: #ffae4d;
}


.main-payment .Current-Package a:hover{
    color: inherit
}


.main-payment .Current-Package .btn{
    height: 47px;
}

.main-payment .Current-Package .btn:hover , .main-payment .Current-Package .btn:focus , .main-payment .Current-Package .btn-primary:not(:disabled):not(.disabled):active{
    background: #ffad4d;
    box-shadow:none;
}



/* width: 450px; */


.main-payment .confirm-Plan.modal-dialog{
    max-width: 450px;
}

.main-payment .modal .Plans-box .card-header {
    background: white;
    text-align: center;
}



.main-payment .Package-circle{
    height: 180px;
    width: 180px;
    background-color: #f3f3f3;
    border-radius: 50%;
    position: relative;
    display: inline-block;
  }


  .main-payment .Package-circle .Pkg-price{
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);

  }

/* ------------ */

.main-payment .history-card .card-body{
    border: 1px solid #ddd;
    padding: 1rem;
}

.main-payment .history-card .card-body .row:nth-child(odd){
    background: #efefef;
}


.main-payment .history-card .card-body p{
    padding: 1rem;
    /* margin: 0 */
}

/* ///--------end main containder --------//// */


/* ///---------------start Plans-box-------------// */

.main-payment .Plans-box .card-header{
    background: #ffad4d;
    text-align: center;
}

.main-payment .Plans-box .card-header .h4{
    line-height: 50px;
    margin: 0;
}

.main-payment .Plans-box .col-12:first-child .card-header {
    border-top-right-radius: 65px;
}

.main-payment .Plans-box .col-12:first-child .card {
    border-top-right-radius: 66px;
}

.main-payment .Plans-box .col-12:last-child .card-header {
    border-top-left-radius: 65px;
}

.main-payment .Plans-box .col-12:last-child .card {
    border-top-left-radius: 66px;
}
.main-payment .Plans-box .div-box{
    border: 1px solid #ddd;
    padding: 1rem;
}

.main-payment .Plans-box .Pkg-price{
    display: inline-block;
}

.main-payment .Plans-box .Pkg-price:first-child{
    text-align: right !important;
}

.main-payment .Plans-box .h1{
    font-size: 3rem;
}

.main-payment .Plans-box .Pkg-price p , .main-payment .Plans-box .Pkg-price p span{
    font-size: 1rem;
    /* margin-right: 9px; */
}

.main-payment .Plans-box .Pkg-price p span{
    margin: 0 3px;
}


.main-payment .Plans-box .Pkg-price p span:last-child{
    text-decoration: line-through;
    display: inline-block;
}

.main-payment .Plans-box .Pkg-hint{
    background: #ececec;
    border-radius: 2rem;
    margin: .55rem 0;
}

.main-payment .special-offer{
    background: #fa5a53;
    color: white;
    position: relative;
    line-height: 35px;
}

.main-payment .special-offer:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 6%;
    bottom: -14px;
    border-left: 0px solid transparent;
    border-right: 17px solid transparent;
    border-top: 14px solid #fa5a53;
}

.Plans-box .div-box p.danger-color span{
    display: inline-block;
    margin: 0 3px;
}


.ui.modal.MobilePaymentModal>.content{
    padding: 0px !important;
}

.confirm-Plan.modal-dialog.MobilePaymentModal {
    max-width: 450px !important;
}

.confirm-Plan.modal-dialog.MobilePaymentModal .Pkg-hint{
    background: #f9f9f9;
}

.Plans-card-header {
    line-height: 50px !important;
    margin: 0;
}

.confirm-Plan.modal-dialog.MobilePaymentModal .main-payment .Package-circle{
    background-color: #f9f9f9;
}


/* ///---------------end Plans-box-------------// */

/*\\\\\\\\\\\\\\\\\\\\v\ end index page\\\\\\\\\\\\\\\\\\\\\\\\\ */


.paymentltr{
direction: ltr;
/* left: 0 !important;
margin: 0 !important;
padding: 0 !important; */
}











/*\\\\\\\\\\\\\\\\\\\\\\\\\\\\ start footer \\\\\\\\\\\\\\\\\\\\\\\\\ */

.main-payment .copyrights{
    width: 100%;
    text-align: center;
    padding: 1rem;
    color: #363636;
}

/*\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ end footer \\\\\\\\\\\\\\\\\\\\\\\\\ */

/* ///////////////////// start login //////////////////// */

.login{
    width: 350px !important;
}

.login .custom-control-label::before {
    top: 0rem;
    right: 0rem;
}


.login .custom-control-label::after {
    top: 0rem;
    right: 0rem;
}

.login .custom-control-label {
    padding-right: 23px;
}


.or-box
{
    position: relative;
    border-top: 1px solid #dfdfdf;
    padding-top: 20px;
    margin-top:20px;
}

.or
{
    color: #b5b5b5;
    background-color: #F7F7F7;
    position: absolute;
    text-align: center;
    top: -11px;
    /* width: 40px; */
    left: 90px;
    padding: 0 1rem;
}


.login .socialButtons .btn-primary{
    display: inline-block;
    width: 50px;
    height: 40px;
    vertical-align: center;
    text-align: center;
}

.login .socialButtons .btn-primary i{
    line-height: 40px;
    color: #fff;
}

.login .socialButtons .facebook {background: #3b5998; -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
         transition: all 0.5s ease-in-out;
}
.login .socialButtons .facebook:hover {background: #172d5e}
.login .socialButtons .facebook:focus {background: #fff; color:#3b5998; border-color: #3b5998;}

.login .socialButtons .twitter {background: #00aced; -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
         transition: all 0.5s ease-in-out;}
.login .socialButtons .twitter:hover {background: #043d52}
.login .socialButtons .twitter:focus {background: #fff; color:#00aced; border-color: #00aced;}

.login .socialButtons .google-plus {background: #c32f10; -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
         transition: all 0.5s ease-in-out;}
.login .socialButtons .google-plus:hover {background: #6b1301}
.login .socialButtons .google-plus:focus {background: #fff; color: #c32f10; border-color: #c32f10}



/* ////////////////////// end login ///////////////////// */

/*////////////////////// updated //////////////////////////////*/


/* ///---------------start Plans-box-------------// */



/* ---------------------------- */


.tabs {
    max-width: 450px;
    margin: 0 1rem;
    padding-bottom: 1rem;
    -moz-box-shadow: 0px 0px 60px 10px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 60px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 60px 10px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: .8rem;
  }
  #tab-button {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  #tab-button li {
    display: table-cell;
    width: 20%;
  }
  #tab-button li a {
    display: block;
    /* padding: .5em; */
    background: #eee;
    /* border: 1px solid #ddd; */
    text-align: center;
    color: #000;
    text-decoration: none;
  }
  #tab-button li:not(:first-child) a {
    border-left: none;
  }
  #tab-button li a:hover,
  #tab-button .is-active a {
    border-bottom-color: transparent;
    background: #fff;
  }
  
  /* .tab-contents { */
    /* padding: .5em 2em 1em; */
    /* border: 1px solid #ddd; */
  /* } */
  
  
  
  /* .tab-button-outer {
    display: none;
  } */
  .tab-contents {
    margin-top: 20px;
  }
  @media screen and (min-width: 768px) {
    .tab-button-outer {
      position: relative;
      z-index: 2;
      display: block;
    }
    .tab-select-outer {
      display: none;
    }
    .tab-contents {
      position: relative;
      top: -1px;
      margin-top: 0;
    }
  }

 #tab-button li:first-child , #tab-button li:first-child a ,  #tab-button li:first-child a .card-header{
      border-top-right-radius: .8rem;
      border-top-left-radius: 0;
      cursor: pointer;
  }

 #tab-button li:last-child , #tab-button li:last-child a , #tab-button li:last-child a .card-header{
    border-top-left-radius: .8rem;
    border-top-right-radius: 0rem;
    cursor: pointer;
  }


 #tab-button .card-header{
    background: #fec27a;
    text-align: center;
    color: #d39a55;
}

#tab-button > .is-active .card-header {
    background: #ffad4d;
    color: #212529;
  }


/* ----------------------------- */







.Plans-box .card-header{
    background: #ffad4d;
    text-align: center;
}

.Plans-box .card-header .h4{
    font-size: 1rem;
    line-height: 50px;
    margin: 0;
}

.Plans-box .col-12:first-child .card-header {
    border-top-right-radius: 65px;
}

.Plans-box .col-12:first-child .card {
    border-top-right-radius: 66px;
}

.Plans-box .col-12:last-child .card-header {
    border-top-left-radius: 65px;
}

.Plans-box .col-12:last-child .card {
    border-top-left-radius: 66px;
}
.Plans-box .div-box{
    border: 1px solid #ddd;
    padding: 1rem;
}

.Plans-box .Pkg-price{
    display: inline-block;
}

.Plans-box .Pkg-price:first-child{
    text-align: right !important;
}

.Plans-box .h1{
    font-size: 3rem;
}

.Plans-box .Pkg-price p span{
    text-decoration: line-through;
}

.Plans-box .Pkg-hint{
    background: #f9f9f9;
    /* color: #848484; */
    border-radius: 2rem;
}

.Plans-box .special-offer{
    background: #fa5a53;
    color: white;
    position: relative
}

.Plans-box .special-offer:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 6%;
    bottom: -14px;
    border-left: 0px solid transparent;
    border-right: 17px solid transparent;
    border-top: 14px solid #fa5a53;
}

/* ///---------------end Plans-box-------------// */

/*\\\\\\\\\\\\\\\\\\\\v\ end index page\\\\\\\\\\\\\\\\\\\\\\\\\ */


